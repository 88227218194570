import React, { ReactElement } from "react";
import { DsBox, DsTypography } from "@am92/react-design-system";
import { BottomSticker } from "./BottomSticker";

type ErrorLayoutProps = {
  errorIcon?: ReactElement;
  title: string;
  description: string;
  additionalDescription?: string;
  bottomSpacerSize?: string | number;
  bottomStickerRender: ReactElement;
};

export const ErrorLayout = ({
  errorIcon,
  title,
  description,
  additionalDescription,
  bottomSpacerSize,
  bottomStickerRender,
}: ErrorLayoutProps) => {
  return (
    <>
      <DsBox>
        <DsBox
          display="flex"
          alignItems="center"
          flexDirection="column"
          mt="140px"
          mx={6}
        >
          {errorIcon}
          <DsTypography
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              letterSpacing: 0.3,
              lineHeight: "22px",
              mt: "12px",
              textAlign: "center",
            }}
          >
            {title}
          </DsTypography>
          <DsTypography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              letterSpacing: 0.26,
              lineHeight: "20px",
              mt: "12px",
              textAlign: "center",
              color: "#6E6E6E",
            }}
          >
            {description}
          </DsTypography>
          {additionalDescription && (
            <DsTypography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                letterSpacing: 0.3,
                lineHeight: "16px",
                mt: 4,
                textAlign: "center",
                color: "#404040",
              }}
            >
              {additionalDescription}
            </DsTypography>
          )}
        </DsBox>
      </DsBox>
      <BottomSticker>
        <DsBox sx={{ backgroundColor: "white", p: 4 }}>
          {bottomStickerRender}
        </DsBox>
      </BottomSticker>
    </>
  );
};
