import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Cashback/Selectors/CashbackTransactionDetail.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";

import { PaymentDetail } from "~/src/Redux/Payment/Model/PaymentDetail.model";

export type CashbackTransactionListState = {
  cashbackTransactionData: IRemoteData<PaymentDetail[]>;
};

const initialState: CashbackTransactionListState = {
  cashbackTransactionData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchCashbackTransactionList(state) {
      state.cashbackTransactionData =
        state.cashbackTransactionData.setLoading();
    },
    fetchCashbackTransactionListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.cashbackTransactionData = state.cashbackTransactionData.setError(
        action.payload
      );
    },
    fetchCashbackTransactionListSuccess(
      state,
      action: PayloadAction<PaymentDetail[]>
    ) {
      state.cashbackTransactionData = state.cashbackTransactionData.setData(
        action.payload
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const cashbackTransactionSlice = createSlice(sliceOptions);

export default cashbackTransactionSlice.reducer;
export const {
  fetchCashbackTransactionList,
  fetchCashbackTransactionListFail,
  fetchCashbackTransactionListSuccess,
} = cashbackTransactionSlice.actions;
