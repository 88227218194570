import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "./Selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { PaymentDetail } from "../Payment/Model/PaymentDetail.model";

export type HistoryListState = {
  historyDetailData: IRemoteData<PaymentDetail[]>;
  historyDetailStatus: DataStatus;
};

type HistoryActionType = {
  getHistoryDetails: (
    customerId: string,
    oneYear: boolean
  ) => Promise<PaymentDetail[]>;
  setHistoryStatusNotLoaded: () => void;
};

const initialState: HistoryListState = {
  historyDetailData: new RemoteData(),
  historyDetailStatus: DataStatus.NOT_LOADED,
};

// interface StoreParams {
//   customerDetail: object
// }

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchHistoryList(state) {
      state.historyDetailData = state.historyDetailData.setLoading();
      state.historyDetailStatus = DataStatus.LOADING;
    },
    fetchHistoryListFail(state, action: PayloadAction<ApiResponseError>) {
      state.historyDetailData = state.historyDetailData.setError(
        action.payload
      );
      state.historyDetailStatus = DataStatus.ERRORED;
    },
    fetchHistoryListSuccess(state, action: PayloadAction<PaymentDetail[]>) {
      state.historyDetailData = state.historyDetailData.setData(action.payload);
      state.historyDetailStatus = DataStatus.LOADED;
    },
    setHistoryStatusNotLoaded(state, action: PayloadAction) {
      state.historyDetailStatus = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    // builder.addCase(getCategoryLogoAction, (state, { payload }: any) => {
    //   state.categoryList = payload
    // })
  },
};

const historyListSlice = createSlice(sliceOptions);

export default historyListSlice.reducer;
export const {
  fetchHistoryList,
  fetchHistoryListSuccess,
  fetchHistoryListFail,
  setHistoryStatusNotLoaded,
} = historyListSlice.actions;
