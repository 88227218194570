import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Cashback/Selectors/CashbackCustomerDetail.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { CashbackDetailData } from "~/src/Redux/Cashback/Model/cashbackDetail.model";

export type CashbackDetailListState = {
  cashbackDetail: IRemoteData<CashbackDetailData>;
};

const initialState: CashbackDetailListState = {
  cashbackDetail: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchCashbackDetailList(state) {
      state.cashbackDetail = state.cashbackDetail.setLoading();
    },
    fetchCashbackDetailListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.cashbackDetail = state.cashbackDetail.setError(action.payload);
    },
    fetchCashbackDetailListSuccess(
      state,
      action: PayloadAction<CashbackDetailData>
    ) {
      state.cashbackDetail = state.cashbackDetail.setData(action.payload);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const cashbackCustomerDetailSlice = createSlice(sliceOptions);

export default cashbackCustomerDetailSlice.reducer;
export const {
  fetchCashbackDetailList,
  fetchCashbackDetailListFail,
  fetchCashbackDetailListSuccess,
} = cashbackCustomerDetailSlice.actions;
