import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "modifyRegisteredBillerList";

const select = (state: any) => state[SLICE_NAME];
const getModifyRegisteredBillerListSelect = (state: any) => state[SLICE_NAME];

export const getModifyRegisteredBillerSelector = createSelector(
  getModifyRegisteredBillerListSelect,
  (modifyRegisteredBillerList: any) => modifyRegisteredBillerList
);
