export interface ElementIneterface {
  event: string;
  category: string;
  action: string;
  label: string;
  cd2: string; //"<<pass encrypted customer id>>";
  cd24: string; //"<<pass platform either website or internet-banking or mobile-app>>";
  page?: string;
  title?: string;
}

export interface homePageHelperInterface {
  eventPayBills: string;
  categoryPayBills: string;
  categoryType: {
    [key: string]: {
      action: string;
    };
  };
  cd24: string;
  cd2: string;
  actions: {
    [key: string]: string;
  };
  labels: {
    [key: string]: string;
  };
  page: string;
  title: string;
}

export interface CategoryHelperInterface {
  eventPayBills: string;
  categoryPayBills: string;
  cd24: string;
  cd2: string;
  action: string;
  labels: string;
  page: string;
  title: string;
}

export const homePageHelperObject: homePageHelperInterface = {
  eventPayBills: "event_pay_bills",
  categoryPayBills: "pay bills",
  categoryType: {
    "Bill Payments and Recharges": {
      action: "Bill Payments and Recharges",
    },
    Finance: {
      action: "Finance",
    },
    Others: {
      action: "Others",
    },
    "My Bills and Recharge": {
      action: "manage billers",
    },
  },
  cd24: "",
  cd2: "",
  actions: {
    banner: "banner",
    click: "click",
    quickLinks: "quick links",
    recentTransaction: "recent transaction",
  },
  labels: {
    banner: " bill",
    click: " back",
  },
  page: "",
  title: "",
};

export const CategoryHelperObject: CategoryHelperInterface = {
  eventPayBills: "event_pay_bills",
  categoryPayBills: "pay bills | ",
  action: "",
  cd24: "",
  cd2: "",
  labels: "",
  page: "",
  title: "",
};
