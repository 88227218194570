import React, { lazy } from "react";
import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import { RouteObject } from "react-router-dom";
import { NavigateOptions, To } from "react-router-dom";

type PaymentRouteItem = {
  pathname: To;
  options?: NavigateOptions;
};

type PaymentRoute = {
  [key: string]: PaymentRouteItem;
};

const HistoryReceiptPage = React.lazy(
  () => import("~/src/Pages/History/components/History-receipt.page")
);

const PaymentReceiptPage = React.lazy(
  () => import("~/src/Pages/Payment/payment-recipt.page")
);

const PaymentPage = React.lazy(
  () => import("~/src/Pages/Payment/payment.page")
);

const ScheduledBillerReciptPage = React.lazy(
  () => import("~/src/Pages/ScheduleBiller/ScheduleBiller-page")
);

export const PAYMENT_ROUTES: PaymentRoute = {
  HISTORY_STATUS: {
    pathname: `${APP_ROUTES.PAYMENT.pathname}/transaction-history-status`,
  },
  PAYMENT: {
    pathname: `${APP_ROUTES.PAYMENT.pathname}/payment`,
  },
  PAYMENT_STATUS: {
    pathname: `${APP_ROUTES.PAYMENT.pathname}/payment-status`,
  },
  SCHEDULED_BILLER_STATUS: {
    pathname: `${APP_ROUTES.PAYMENT.pathname}/scheduled-status`,
  },
};

export const paymentRoutes: RouteObject[] = [
  {
    path: `${PAYMENT_ROUTES.HISTORY_STATUS.pathname}`,
    element: <HistoryReceiptPage />,
  },
  {
    path: `${PAYMENT_ROUTES.PAYMENT.pathname}`,
    element: <PaymentPage />,
  },
  {
    path: `${PAYMENT_ROUTES.PAYMENT_STATUS.pathname}`,
    element: <PaymentReceiptPage />,
  },
  {
    path: `${PAYMENT_ROUTES.SCHEDULED_BILLER_STATUS.pathname}`,
    element: <ScheduledBillerReciptPage />,
  },
];
