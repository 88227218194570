import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Prepaid/Selectors/PrepaidOperator.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { OperatorCircle } from "~/src/Redux/Prepaid/Model/prepaid.model";

export type PrepaidOperatorListState = {
  prepaidOperatorListData: IRemoteData<OperatorCircle>;
};
const initialState: PrepaidOperatorListState = {
  prepaidOperatorListData: new RemoteData(),
};
const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchPrepaidOperatorList(state) {
      state.prepaidOperatorListData =
        state.prepaidOperatorListData.setLoading();
    },
    fetchPrepaidOpeartorListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.prepaidOperatorListData = state.prepaidOperatorListData.setError(
        action.payload
      );
    },
    fetchPrepaidOperatorListSuccess(
      state,
      action: PayloadAction<OperatorCircle[]>
    ) {
      state.prepaidOperatorListData = state.prepaidOperatorListData.setData(
        action.payload
      );
    },
    setOperatorStatusNotLoaded(state) {
      state.prepaidOperatorListData.status = DataStatus.NOT_LOADED;
      state.prepaidOperatorListData = new RemoteData();
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};
const prepaidOperatorListSlice = createSlice(sliceOptions);
export default prepaidOperatorListSlice.reducer;
export const {
  fetchPrepaidOperatorList,
  fetchPrepaidOpeartorListFail,
  fetchPrepaidOperatorListSuccess,
  setOperatorStatusNotLoaded,
} = prepaidOperatorListSlice.actions;
