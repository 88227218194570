import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Support/Selectors/ComplaintType.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { ComplaintType } from "~/src/Redux/Support/Model/ComplaintType.model";

export type ComplaintTypesListState = {
  complaintTypesDetailData: IRemoteData<ComplaintType[]>;
};

const initialState: ComplaintTypesListState = {
  complaintTypesDetailData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchComplaintTypesList(state) {
      state.complaintTypesDetailData =
        state.complaintTypesDetailData.setLoading();
    },
    fetchComplaintTypesListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.complaintTypesDetailData = state.complaintTypesDetailData.setError(
        action.payload
      );
    },
    fetchComplaintTypesListSuccess(
      state,
      action: PayloadAction<ComplaintType[]>
    ) {
      state.complaintTypesDetailData = state.complaintTypesDetailData.setData(
        action.payload
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const complaintTypesSlice = createSlice(sliceOptions);

export default complaintTypesSlice.reducer;
export const {
  fetchComplaintTypesList,
  fetchComplaintTypesListFail,
  fetchComplaintTypesListSuccess,
} = complaintTypesSlice.actions;
