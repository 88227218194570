import React, { lazy } from "react";
import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import { RouteObject } from "react-router-dom";
import { NavigateOptions, To } from "react-router-dom";

type SupportRouteItem = {
  pathname: To;
  options?: NavigateOptions;
};

type SupportRoute = {
  [key: string]: SupportRouteItem;
};

const PastTicketsPage = React.lazy(
  () => import("~/src/Pages/Support/PastTicket.page")
);

const ComplaintPage = React.lazy(
  () => import("~/src/Pages/Support/Complaint.page")
);

const TicketDetailPage = React.lazy(
  () => import("~/src/Pages/Support/TicketDetail.page")
);

const TutorialPage = React.lazy(
  () => import("~/src/Pages/Support/Tutorial.page")
);

export const SUPPORT_ROUTES: SupportRoute = {
  PAST_TICKETS: {
    pathname: `${APP_ROUTES.SUPPORT.pathname}/past-tickets`,
  },
  COMPLAINT: {
    pathname: `${APP_ROUTES.SUPPORT.pathname}/complaint`,
  },
  TICKET_DETAIL: {
    pathname: `${APP_ROUTES.SUPPORT.pathname}/ticket-details`,
  },
  TUTORIAL_PAGE: {
    pathname: `${APP_ROUTES.SUPPORT.pathname}/tutorial`,
  },
};

export const supportRoutes: RouteObject[] = [
  {
    path: `${SUPPORT_ROUTES.PAST_TICKETS.pathname}`,
    element: <PastTicketsPage />,
  },

  {
    path: `${SUPPORT_ROUTES.COMPLAINT.pathname}`,
    element: <ComplaintPage />,
  },

  {
    path: `${SUPPORT_ROUTES.TICKET_DETAIL.pathname}`,
    element: <TicketDetailPage />,
  },

  {
    path: `${SUPPORT_ROUTES.TUTORIAL_PAGE.pathname}`,
    element: <TutorialPage />,
  },
];
