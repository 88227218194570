import { createSelector } from '@reduxjs/toolkit'

export const SLICE_NAME = 'prepaidPlansList'

const select = (state: any) => state[SLICE_NAME]
const getPrapaidPlansListSelect = (state: any) => state[SLICE_NAME]

export const getPrepaidPlans = createSelector(
  getPrapaidPlansListSelect,
  (prepaidPlanList: any) => prepaidPlanList
)
