import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "complaintStatusList";

const select = (state: any) => state[SLICE_NAME];
const getComplaintStatusListSelect = (state: any) => state[SLICE_NAME];

export const getComplaintStatusSelector = createSelector(
  getComplaintStatusListSelect,
  (complaintStatusList: any) => complaintStatusList
);
