import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selector'
import { getCategoryLogoAction } from './Actions'
import {
  DataStatus,
  IRemoteData,
  RemoteData
} from '~/src/Lib/types/datatransfer'
import { CategoryList } from './Model'
import { ApiResponseError } from '~/src/Lib/types/api'

type CategoryLogo = {
  [key: string]: string
}

type CashbackCategoryLogo = {
  [key: string]: string
}
export type CategoryListState = {
  categoryList: IRemoteData<CategoryList[]>
  categoryLogo: object
  cashbackCategoryLogo: object
}

const categoryLogoResolver = (categoryList: CategoryList[]): CategoryLogo => {
  const categoryLogo: CategoryLogo = {}
  categoryList.forEach(item =>
    item.categoryList.forEach(cat => {
      categoryLogo[cat.categoryName] = cat.categoryLogo
    })
  )
  return categoryLogo
}

const cashbackCategoryLogoResolver = (
  categoryList: CategoryList[]
): CashbackCategoryLogo => {
  const categoryLogo: CashbackCategoryLogo = {}
  categoryList.forEach(item =>
    item.categoryList.forEach(cat => {
      categoryLogo[cat.categoryName.toLowerCase()] = cat.categoryLogo
    })
  )
  return categoryLogo
}

// interface StoreParams {
//   customerDetail: object
// }

const initialState: CategoryListState = {
  categoryList: new RemoteData(),
  categoryLogo: {} as CategoryLogo,
  cashbackCategoryLogo: {} as CashbackCategoryLogo
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchCategoryList(state) {
      state.categoryList = state.categoryList.setLoading()
    },
    fetchCategoryListFail(state, action: PayloadAction<ApiResponseError>) {
      state.categoryList = state.categoryList.setError(action.payload)
    },
    fetchCategoryListSuccess(state, action: PayloadAction<CategoryList[]>) {
      state.categoryList = state.categoryList.setData(action.payload)
      state.categoryLogo = categoryLogoResolver(action.payload)
      state.cashbackCategoryLogo = cashbackCategoryLogoResolver(action.payload)
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    // builder.addCase(getCategoryLogoAction, (state, { payload }: any) => {
    //   state.categoryList = payload
    // })
  }
}

const categoryListSlice = createSlice(sliceOptions)

export default categoryListSlice.reducer
export const {
  fetchCategoryList,
  fetchCategoryListSuccess,
  fetchCategoryListFail
} = categoryListSlice.actions
