import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "downloadReceipt";

const select = (state: any) => state[SLICE_NAME];
export const downloadReceiptSelect = (state: any) => state[SLICE_NAME];

export const downloadReceiptSelector = createSelector(
  downloadReceiptSelect,
  (downloadReceiptData: any) => downloadReceiptData
);

export const getdownloadReceiptRequest = createSelector(
  (state) => state[SLICE_NAME].downloadRecieptRequest,
  (downloadRecieptRequest) => downloadRecieptRequest
);
