import { NavigateOptions, To } from "react-router-dom";

type AppRouteItem = {
  pathname: To;
  options?: NavigateOptions;
};

type AppRoute = {
  [key: string]: AppRouteItem;
};

const APP_ROUTES: AppRoute = {
  HOME: {
    pathname: "/",
  },
  NOTIFCATION: {
    pathname: "/notification",
  },
  OVERVIEW: { pathname: "/overview" },
  SEARCH: { pathname: "/search" },
  SUPPORT: { pathname: "/support" },
  FAQ: { pathname: "/faq" },
  BILLERS: { pathname: "/biller" },
  PAYMENT: { pathname: "/payment" },
  HISTORY_STATUS: { pathname: "/payment/transaction-history-status" },
  MANAGE_BILLER: { pathname: `/manage-billers` },
  MANAGE_BILLER_PAY_HISTORY: {
    pathname: `/manage-billers/manage-payment-history`,
  },
  SOMETHING_WENT_WRONG: { pathname: "/something-went-wrong" },
  ACCOUNT_LOCKED: { pathname: "/account-locked" },
  VERIFY_PAGE: { pathname: "/verify-detail" },
  HISTORY_PAGE: { pathname: `/transaction-history` },
  TECHNICAL_ERROR: { pathname: "/technical-error" },
  SESSION_EXPIRED: { pathname: "/session-expired" },
  UNDER_MAINTENANCE: { pathname: "/maintenance" },
  CASHBACK: { pathname: "/cashback" },
  CASHBACK_WELCOME: { pathname: "/cashback-welcome" },
  UPDATE_INFORMATION: { pathname: "/update-information" },

  ANY: {
    pathname: "*",
  },
};

export default APP_ROUTES;
