import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "raiseComplaintList";

const select = (state: any) => state[SLICE_NAME];
const getRaiseComplaintListSelect = (state: any) => state[SLICE_NAME];

export const getRaiseComplaintSelector = createSelector(
  getRaiseComplaintListSelect,
  (raiseComplaintList: any) => raiseComplaintList
);
