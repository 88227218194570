import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "handshake";

const select = (state: any) => state[SLICE_NAME];
const handshakeSelect = (state: any) => state[SLICE_NAME].handshakeStatus;

export const handshakeSelector = createSelector(
  handshakeSelect,
  (handshakeStatus: any) => handshakeStatus
);
