import { combineReducers, ReducersMapObject } from "redux";

import AuthReducer from "./Auth/Reducer";
import { SLICE_NAME as AuthSliceName } from "./Auth/Selectors";

import ServiceTrackerReducer from "./ServiceTracker/Reducer";
import { SLICE_NAME as ServiceTrackerSliceName } from "./ServiceTracker/Selectors";
import BannersReducer from "./Banners/Reducer";
import { SLICE_NAME as BannerSliceName } from "./Banners/Selectors";
import { SLICE_NAME as CustomerSliceName } from "./Customer/Customer.selector";
import CustomerReducer from "./Customer/Customer.reducer";
import { SLICE_NAME as CategoryListSliceName } from "./Categories/Selector";
import CategoryListReducer from "./Categories/Reducer";
import HistoryListReducer from "./History/Reducer";
import { SLICE_NAME as HistoryListSliceName } from "./History/Selector";
import { SLICE_NAME as BillerAccountSlice } from "./Billers/Selectors/BillerAccount.selector";
import BillerAccountReducer from "./Billers/Reducers/BillerAccount.reducer";
import { SLICE_NAME as SearchServiceSlice } from "./Billers/Selectors/SearchService.selector";
import SearchServiceReducer from "./Billers/Reducers/SearchService.reducer";
import { SLICE_NAME as ComplaintTypesSliceName } from "./Support/Selectors/ComplaintType.selector";
import ComplaintTypeReducer from "./Support/Reducers/ComplaintType.reducer";
import RaiseComplaintReducer from "./Support/Reducers/RaiseComplaint.reducer";
import { SLICE_NAME as RaiseComplaintSliceName } from "./Support/Selectors/RaiseComplaint.selector";
import { SLICE_NAME as ValidatePaymentSlice } from "./Billers/Selectors/ValidatePayment.selector";
import ValidatePaymentReducer from "./Billers/Reducers/ValidatePayment.reducer";
import { SLICE_NAME as BillerSliceName } from "./Billers/Selectors/Biller.selector";
import BillerReducer from "./Billers/Reducers/Biller.reducer";
import PastTicketsReducer from "./Support/Reducers/PastTickets.reducer";
import { SLICE_NAME as PastTicketsSliceName } from "./Support/Selectors/PastTickets.selector";
import ComplaintStatusReducer from "./Support/Reducers/ComplaintStatus.reducer";
import { SLICE_NAME as ComplaintStatusSliceName } from "./Support/Selectors/ComplaintStatus.selector";
import AccountDetailsReducers from "./Payment/Reducers/AccountDetails.reducers";
import { SLICE_NAME as AccountDetailsSliceName } from "./Payment/Selectors/AccountDetails.selector";
import CreditCardDetailsReducers from "./Payment/Reducers/CreditCardDetails.reducers";
import { SLICE_NAME as CreditCardDetailsSliceName } from "./Payment/Selectors/CreditCardDetails.selector";
import RegisterBillerReducer from "./Billers/Reducers/RegisterBiller.reducer";
import { SLICE_NAME as RegisterBillerDetailsSliceName } from "./Billers/Selectors/RegisterBiller.selector";
import CityListDetailsReducer from "./Billers/Reducers/CityList.reducer";
import { SLICE_NAME as CityDetailsSliceName } from "~/src/Redux/Billers/Selectors/CityList.selector";
import PrepaidOperatorReducer from "./Prepaid/Reducers/PrepaidOperator.reducer";
import { SLICE_NAME as PrepaidOperatorSliceName } from "./Prepaid/Selectors/PrepaidOperator.selector";
import PrepaidCircleReducer from "./Prepaid/Reducers/PrepaidCircle.reducer";
import { SLICE_NAME as PrepaidCircleSliceName } from "./Prepaid/Selectors/PrepaidCircle.selector";
import PrepaidPlansReducer from "./Prepaid/Reducers/PrepaidPlans.reducer";
import { SLICE_NAME as PrepaidPlansSliceName } from "./Prepaid/Selectors/PrepaidPlans.selector";
import MakePaymentReducer from "./Payment/Reducers/MakePayment.reducers";
import { SLICE_NAME as MakePaymentSliceName } from "./Payment/Selectors/MakePayment.selector";
import SendOtpReducer from "./Payment/Reducers/SendOtp.reducers";
import { SLICE_NAME as SendOtpSliceName } from "./Payment/Selectors/SendOtp.selector";
import ValidateOtpReducer from "./Payment/Reducers/ValidateOtp.reducers";
import { SLICE_NAME as ValidateOtpSliceName } from "./Payment/Selectors/ValidateOtp.selector";
import MpinReducer from "./Payment/Reducers/Mpin.reducers";
import { SLICE_NAME as MpinSliceName } from "./Payment/Selectors/Mpin.selector";
import CashbackActiveOfferReducer from "./Cashback/Reducers/CashbackActiveOffer.reducer";
import { SLICE_NAME as CashbackActiveOfferSliceName } from "~/src/Redux/Cashback/Selectors/CashbackActiveOffer.selector";
import CashbackCustomerDetailReducer from "./Cashback/Reducers/CashbackCustomerDetail.reducer";
import { SLICE_NAME as CashbackCustomerDetailSliceName } from "~/src/Redux/Cashback/Selectors/CashbackCustomerDetail.selector";
import RecentCashbackDetailReducer from "./Cashback/Reducers/RecentCashbackDetail.reducer";
import { SLICE_NAME as RecentCashbackDetailSliceName } from "~/src/Redux/Cashback/Selectors/RecentCashbackDetail.selector";
import MilestonesDetailReducer from "~/src/Redux/Cashback/Reducers/MilestonesDetail.reducer";
import { SLICE_NAME as MilestonesDetailSliceName } from "~/src/Redux/Cashback/Selectors/MilestonesDetail.selector";
import CashbackTransactionDetailReducer from "./Cashback/Reducers/CashbackTransactionDetail.reducer";
import { SLICE_NAME as CashbackTransactionDetail } from "~/src/Redux/Cashback/Selectors/CashbackTransactionDetail.selector";
import TncDetailReducer from "~/src/Redux/T&C/Reducers/TermsConditions.reducer";
import { SLICE_NAME as TncDetailSliceName } from "~/src/Redux/T&C/Selectors/TermsConditions.selector";
import DeleteRegisterBillerReducer from "./Billers/Reducers/DeleteRegisterBiller.Reducer";
import { SLICE_NAME as DeleteBillerSliceName } from "~/src/Redux/Billers/Selectors/DeleteRegisterBiller.selector";
import ModifyRegisteredBillerReducer from "./Billers/Reducers/ModifyRegisteredBiller.reducer";
import { SLICE_NAME as ModifyBillerSliceName } from "~/src/Redux/Billers/Selectors/ModifyRegisteredBiller.selector";
import ScheduledBillersReducer from "~/src/Redux/Billers/Reducers/ScheduledBillers.reducer";
import { SLICE_NAME as ScheduledBillersSliceName } from "~/src/Redux/Billers/Selectors/ScheduledBillers.selector";
import StopScheduledPaymentReducer from "~/src/Redux/Payment/Reducers/StopScheduledPayment.reducer";
import { SLICE_NAME as StopScheduledPaymentSliceName } from "~/src/Redux/Payment/Selectors/StopScheduledPayment.selector";
import { SLICE_NAME as LoaderSliceName } from "~/src/Redux/Loaders/Selectors/Loaders.selector";
import ValidateBillReducer from "~/src/Redux/Billers/Reducers/ValidateBill.reducer";
import { SLICE_NAME as ValidateBillSliceName } from "~/src/Redux/Billers/Selectors/ValidateBill.selector";
import FaqReducer from "~/src/Redux/FAQ/Faq.reducer";
import { SLICE_NAME as FaqSliceName } from "~/src/Redux/FAQ/Faq.selector";
import LoadersReducers from "./Loaders/Reducers/Loaders.reducers";
import DownloadReceiptReducer from "~/src/Redux/Payment/Reducers/DownloadReceipt.reducer";
import { SLICE_NAME as downloadReceiptSliceName } from "~/src/Redux/Payment/Selectors/DownloadReceipt.selector";
import DiscoveryApiReducer from "~/src/Redux/Splash/Reducer/DiscoveryApi.reducer";
import { SLICE_NAME as DiscoveryApiSliceName } from "~/src/Redux/Splash/Selector/DiscoveryApi.selector";
import CoachMarksRedcuer from "~/src/Redux/CoachMarks/Reducer";
import { SLICE_NAME as CoachMarksSliceName } from "~/src/Redux/CoachMarks/Selector";
import HandshakeReducer from "~/src/Redux/Handshake/Reducer";
import { SLICE_NAME as handshakeSliceName } from "~/src/Redux/Handshake/Selector";

const reducers: ReducersMapObject = {
  [AuthSliceName]: AuthReducer,
  [ServiceTrackerSliceName]: ServiceTrackerReducer,
  [BannerSliceName]: BannersReducer,
  [CustomerSliceName]: CustomerReducer,
  [CategoryListSliceName]: CategoryListReducer,
  [HistoryListSliceName]: HistoryListReducer,
  [BillerAccountSlice]: BillerAccountReducer,
  [SearchServiceSlice]: SearchServiceReducer,
  [ComplaintTypesSliceName]: ComplaintTypeReducer,
  [RaiseComplaintSliceName]: RaiseComplaintReducer,
  [ValidatePaymentSlice]: ValidatePaymentReducer,
  [BillerSliceName]: BillerReducer,
  [PastTicketsSliceName]: PastTicketsReducer,
  [ComplaintStatusSliceName]: ComplaintStatusReducer,
  [AccountDetailsSliceName]: AccountDetailsReducers,
  [CreditCardDetailsSliceName]: CreditCardDetailsReducers,
  [RegisterBillerDetailsSliceName]: RegisterBillerReducer,
  [CityDetailsSliceName]: CityListDetailsReducer,
  [PrepaidOperatorSliceName]: PrepaidOperatorReducer,
  [PrepaidCircleSliceName]: PrepaidCircleReducer,
  [PrepaidPlansSliceName]: PrepaidPlansReducer,
  [MakePaymentSliceName]: MakePaymentReducer,
  [SendOtpSliceName]: SendOtpReducer,
  [ValidateOtpSliceName]: ValidateOtpReducer,
  [MpinSliceName]: MpinReducer,
  [CashbackActiveOfferSliceName]: CashbackActiveOfferReducer,
  [CashbackCustomerDetailSliceName]: CashbackCustomerDetailReducer,
  [RecentCashbackDetailSliceName]: RecentCashbackDetailReducer,
  [MilestonesDetailSliceName]: MilestonesDetailReducer,
  [CashbackTransactionDetail]: CashbackTransactionDetailReducer,
  [TncDetailSliceName]: TncDetailReducer,
  [DeleteBillerSliceName]: DeleteRegisterBillerReducer,
  [ModifyBillerSliceName]: ModifyRegisteredBillerReducer,
  [ScheduledBillersSliceName]: ScheduledBillersReducer,
  [StopScheduledPaymentSliceName]: StopScheduledPaymentReducer,
  [ValidateBillSliceName]: ValidateBillReducer,
  [LoaderSliceName]: LoadersReducers,
  [FaqSliceName]: FaqReducer,
  [downloadReceiptSliceName]: DownloadReceiptReducer,
  [DiscoveryApiSliceName]: DiscoveryApiReducer,
  [CoachMarksSliceName]: CoachMarksRedcuer,
  [handshakeSliceName]: HandshakeReducer,
};

const persistedReducers: any[] = [];

export default combineReducers(reducers);
export { persistedReducers };
