import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "historyList";

const select = (state: any) => state[SLICE_NAME];
const getHistoryListSelect = (state: any) => state[SLICE_NAME];

export const getHistorySelector = createSelector(
  getHistoryListSelect,
  (history: any) => history
);
