import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "./Selectors";

import { AuthModel } from "./Model";
import { ApiResponseError } from "~/src/Lib/types/api";
// import { IRemoteData, RemoteData } from "~/src/Lib/types/dataTransfer";
import traceActionsCreator from "../traceActionsCreator";

export type AuthState = {
  isLoggedIn: boolean;
  accessToken: string;
  refreshToken: string;
};
const initialState: AuthState = {
  isLoggedIn: false,
  accessToken: "",
  refreshToken: "",
};

const authTraceActions = traceActionsCreator("auth");

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    AuthToken(state) {
      state = state.setLoading();
      // authTraceActions.loading();
    },
    AuthTokenSuccess(state, action: PayloadAction) {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      // state.auth = state.auth.setData(action.payload);
    },
    AuthTokenFail(state, action: PayloadAction<ApiResponseError>) {
      // state.AuthData = state.AuthData.setError(action.payload)
      // authTraceActions.error(action.payload);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const AuthSlice = createSlice(sliceOptions);
export const { AuthToken, AuthTokenSuccess, AuthTokenFail } = AuthSlice.actions;
export default AuthSlice.reducer;
