import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "creditCardDetailsList";

const select = (state: any) => state[SLICE_NAME];
const getCreditCardDetailsListSelect = (state: any) => state[SLICE_NAME];

export const getCreditCardDetailsSelector = createSelector(
  getCreditCardDetailsListSelect,
  (creditCardDetailsList: any) => creditCardDetailsList
);
