import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Support/Selectors/RaiseComplaint.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { RaiseComplaintData } from "../Model/RaiseComplaint.model";

export type RaiseComplaintListState = {
  raiseComplaintDetailData: IRemoteData<RaiseComplaintData>;
};

const initialState: RaiseComplaintListState = {
  raiseComplaintDetailData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchRaiseComplaintList(state) {
      state.raiseComplaintDetailData =
        state.raiseComplaintDetailData.setLoading();
    },
    fetchRaiseComplaintListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.raiseComplaintDetailData = state.raiseComplaintDetailData.setError(
        action.payload
      );
    },
    fetchRaiseComplaintListSuccess(
      state,
      action: PayloadAction<RaiseComplaintData>
    ) {
      state.raiseComplaintDetailData = state.raiseComplaintDetailData.setData(
        action.payload
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const raiseComplaintSlice = createSlice(sliceOptions);

export default raiseComplaintSlice.reducer;
export const {
  fetchRaiseComplaintList,
  fetchRaiseComplaintListFail,
  fetchRaiseComplaintListSuccess,
} = raiseComplaintSlice.actions;
