import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "../Selectors/ValidatePayment.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { ValidatePayment } from "../Model/ValidatePayment.model";

export type ValidatePaymentStateType = {
  validateData: IRemoteData<ValidatePayment>;
  validatePaymentData: ValidatePayment;
  validatePaymentStatus: DataStatus;
  validatePaymentError?: ApiResponseError;
};

const initialState: ValidatePaymentStateType = {
  validateData: new RemoteData(),
  validatePaymentData: {} as ValidatePayment,
  validatePaymentStatus: DataStatus.NOT_LOADED,
  validatePaymentError: undefined,
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchValidatePayment(state) {
      state.validateData = state.validateData.setLoading();
      state.validatePaymentStatus = state.validateData.status;
    },
    fetchValidatePaymentFail(state, action: PayloadAction<ApiResponseError>) {
      state.validateData = state.validateData.setError(action.payload);
      state.validatePaymentError = action.payload;
      state.validatePaymentStatus = state.validateData.status;
    },
    fetchValidatePaymentSuccess(state, action: PayloadAction<ValidatePayment>) {
      state.validateData = state.validateData.setData(action.payload);
      state.validatePaymentData = action.payload;
      state.validatePaymentStatus = state.validateData.status;
      state.validatePaymentError = undefined;
    },
    setValidatePaymentStatusNotLoaded(state) {
      state.validatePaymentStatus = DataStatus.NOT_LOADED;
      state.validateData = new RemoteData();
      state.validatePaymentData = {} as ValidatePayment;
      state.validatePaymentError = undefined;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const validatePayment = createSlice(sliceOptions);

export default validatePayment.reducer;
export const {
  fetchValidatePayment,
  fetchValidatePaymentSuccess,
  fetchValidatePaymentFail,
  setValidatePaymentStatusNotLoaded,
} = validatePayment.actions;
