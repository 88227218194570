import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "customerDetail";

const select = (state: any) => state[SLICE_NAME];

// export const getCustomerSelector = (state: any, serviceKey: string) => {
//   return createSelector(
//     (state: any) => state[SLICE_NAME][serviceKey],
//     (serviceKeyValue) => serviceKeyValue
//   )(state);
// };

// export const isCustomerServiceLoading = (state: any, serviceKeys: string[]) => {
//   const loading = serviceKeys.reduce((boolean, serviceKey) => {
//     return boolean || getCustomerSelector(state, serviceKey) === "LOADING";
//   }, false);

//   return loading;
// };

const getCustomerDataSelect = (state: any) => state[SLICE_NAME];

export const getCustomerSelector = createSelector(
  getCustomerDataSelect,
  (customerData: any) => customerData
);

export const getCustomerId = createSelector(
  (state) => state[SLICE_NAME].customerId,
  (customerId) => customerId
);

export const getMobile = createSelector(
  (state) => state[SLICE_NAME].mobile,
  (mobile) => mobile
);

export const getEmailId = createSelector(
  (state) => state[SLICE_NAME].emailId,
  (emailId) => emailId
);

export const getCustomerData = createSelector(
  (state) =>
    state[SLICE_NAME].customerDetail && state[SLICE_NAME].customerDetail.data,
  (customerData) => customerData
);
