import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "complaintTypeList";

const select = (state: any) => state[SLICE_NAME];
const getComplaintTypeListSelect = (state: any) => state[SLICE_NAME];

export const getComplaintTypeSelector = createSelector(
  getComplaintTypeListSelect,
  (complaintList: any) => complaintList
);
