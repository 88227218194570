import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "coachmarks";

const getCoachMarkSelect = (state: any) => state[SLICE_NAME];

export const getCoachMarkSelector = createSelector(
  getCoachMarkSelect,
  (coachmarks: any) => coachmarks
);
