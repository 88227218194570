import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Billers/Selectors/DeleteRegisterBiller.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { DeleteAndModifyBillerInfo } from "../Model/RegisteredBiller.model";

export type DeleteRegisterBillerListState = {
  deleteRegisterBillerData: IRemoteData<DeleteAndModifyBillerInfo>;
};

const initialState: DeleteRegisterBillerListState = {
  deleteRegisterBillerData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchDeleteRegisterBillerList(state) {
      state.deleteRegisterBillerData =
        state.deleteRegisterBillerData.setLoading();
    },
    fetchDeleteRegisterBillerListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.deleteRegisterBillerData = state.deleteRegisterBillerData.setError(
        action.payload
      );
    },
    fetchDeleteRegisterBillerListSuccess(
      state,
      action: PayloadAction<DeleteAndModifyBillerInfo>
    ) {
      state.deleteRegisterBillerData = state.deleteRegisterBillerData.setData(
        action.payload
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const deleteRegisterBillerSlice = createSlice(sliceOptions);

export default deleteRegisterBillerSlice.reducer;
export const {
  fetchDeleteRegisterBillerList,
  fetchDeleteRegisterBillerListFail,
  fetchDeleteRegisterBillerListSuccess,
} = deleteRegisterBillerSlice.actions;
