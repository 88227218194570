import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  getTheme,
  DsCssBaseline,
  Experimental_CssVarsProvider as CssVarsProvider,
  DsNotistackProvider,
} from "@am92/react-design-system";

import {
  getAccessTokenSelector,
  getRefreshTokenSelector,
} from "./Redux/Auth/Selectors";

import { asHttp, webHttp } from "./Configurations/WebHttp";
import getAppRouter from "~/src/Configurations/getAppRouter";
import performHandshake from "~/src/Services/performHandshake";

import {
  PALETTE,
  FONT_FAMILY,
  THEME_MODE_STORAGE_KEY,
  DEFAULT_THEME_MODE,
} from "~/src/Constants/THEME";
import OverlayLoader from "~/src/components/OverlayLoader";

import { WEB_HTTP_CONTEXT, WEB_HTTP_REQ_HEADERS } from "@am92/web-http";
import { handshakeThunk } from "./Redux/Handshake/Service";
import { handshakeSelector } from "./Redux/Handshake/Selector";
import { DataStatus } from "./Lib/types/datatransfer";
// import Onboarding from './Pages/Onboarding/Onboarding'

type Props = {
  persisted: boolean;
  accessToken?: string;
  refreshToken?: string;
  handShakeStatus: string;
  actions: {
    handshakeAction: () => void;
  };
};

type State = {
  hasError: boolean;
  chId: string;
  id: string;
  name: string;
  isLoading: boolean;
  handshakeComplete: boolean;
};

const DefaultState: State = {
  hasError: false,
  chId: "",
  id: "",
  name: "",
  isLoading: false,
  handshakeComplete: false,
};
class App extends Component<Props, State> {
  [x: string]: any;
  state = DefaultState;

  constructor(props: Props) {
    super(props);

    // If you dont want to respect user selected theme
    // and set default theme to one set in THEME constants then uncomment the line
    // this.resetUserThemeToDefault()
  }
  componentDidMount() {
    this.initialize();
    // !this.hasError ? console.log("loaded") : console.log("not loaded");
  }

  initialize = async () => {
    this.setState({ isLoading: true });

    const IS_ENCRYPT =
      (process.env.DISABLE_PAYLOAD_CRYPTOGRAPHY || "").toLowerCase() ===
      "false";
    try {
      await performHandshake();
      if (IS_ENCRYPT) {
        await this.props.actions.handshakeAction(); //oauth
      }

      this.setState({ handshakeComplete: true, isLoading: false });
    } catch (error) {
      console.log("at error");
      this.setState({ isLoading: false, hasError: true });
    }
  };

  resetUserThemeToDefault = () => {
    window.localStorage.removeItem(THEME_MODE_STORAGE_KEY);
  };

  render() {
    let children = <OverlayLoader />;
    const AppTheme = getTheme(PALETTE, FONT_FAMILY);
    const { isLoading, hasError, handshakeComplete } = this.state;
    const { persisted, accessToken, refreshToken, handShakeStatus } =
      this.props;
    if (persisted && handshakeComplete && !isLoading) {
      if (!this.router) {
        this.router = getAppRouter();
      }
      children = <RouterProvider router={this.router} />;

      if (accessToken && refreshToken) {
        asHttp.context.set(WEB_HTTP_CONTEXT.ACCESS_TOKEN, accessToken);
        asHttp.context.set(
          WEB_HTTP_CONTEXT.AUTHENTICATION_TOKEN_KEY,
          WEB_HTTP_REQ_HEADERS.ACCESS_TOKEN
        );
        asHttp.context.set(WEB_HTTP_CONTEXT.REFRESH_TOKEN, refreshToken);
        webHttp.context.set(WEB_HTTP_CONTEXT.ACCESS_TOKEN, accessToken);
        webHttp.context.set(WEB_HTTP_CONTEXT.REFRESH_TOKEN, refreshToken);
      }
    }
    return (
      <CssVarsProvider
        theme={AppTheme}
        defaultMode={DEFAULT_THEME_MODE}
        modeStorageKey={THEME_MODE_STORAGE_KEY}
      >
        <DsNotistackProvider>
          <DsCssBaseline enableColorScheme>
            <Suspense fallback={<OverlayLoader />}>{children}</Suspense>
          </DsCssBaseline>
        </DsNotistackProvider>
        {/* <Onboarding /> */}
      </CssVarsProvider>
    );
  }
}

const mapStateToProps = (state: any) => {
  const accessToken = getAccessTokenSelector(state);
  const refreshToken = getRefreshTokenSelector(state);
  const handShakeStatus = handshakeSelector(state);

  return {
    accessToken,
    refreshToken,
    handShakeStatus,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  actions: {
    handshakeAction: () => dispatch(handshakeThunk()),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
