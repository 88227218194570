import { createSelector } from '@reduxjs/toolkit'

export const SLICE_NAME = 'banners'

const select = (state: any) => state[SLICE_NAME]
const isLoggedInSelect = (state: any) => state[SLICE_NAME].isLoggedIn
const bannerSelect = (state: any) => state[SLICE_NAME].banners
const refreshTokenSelect = (state: any) => state[SLICE_NAME].refreshToken

const getBannersDataSelect = (state: any) => state[SLICE_NAME]

export const getBannerSelector = createSelector(
  getBannersDataSelect,
  (bannersData: any) => bannersData
)
