import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "recentCashbackDetailList";

const select = (state: any) => state[SLICE_NAME];
const getRecentCashbackDetailListSelect = (state: any) => state[SLICE_NAME];

export const getRecentCashbackDetailSelector = createSelector(
  getRecentCashbackDetailListSelect,
  (recentCashbackDetailList: any) => recentCashbackDetailList
);
