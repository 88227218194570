import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "makePaymentList";

const select = (state: any) => state[SLICE_NAME];
const getMakePaymentListSelect = (state: any) => state[SLICE_NAME];

export const getMakePaymentSelector = createSelector(
  getMakePaymentListSelect,
  (makePaymentList: any) => makePaymentList
);
