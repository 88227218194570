import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "mpinDataList";

const select = (state: any) => state[SLICE_NAME];
const getMpinListSelect = (state: any) => state[SLICE_NAME];

export const getMpinSelector = createSelector(
  getMpinListSelect,
  (mpinList: any) => mpinList
);
