import { DownloadRecieptRequest } from "~/src/Redux/Payment/Model/DownloadReceipt.model";
import { PaymentDetail } from "~/src/Redux/Payment/Model/PaymentDetail.model";

export const mapToDownloadRecieptRequest = (
  paymentDetail: PaymentDetail,
  categoryLogo: string
): DownloadRecieptRequest => {
  return {
    paymentid: paymentDetail.paymentId,
    customerid: paymentDetail.customerId,
    billerLogo: paymentDetail.billerLogo,
    billerCategoryLogo: categoryLogo,
  };
};

export const downloadRecieptHelperFunction = (
  base64Data: string,
  currentBillerName: string
): void => {
  const pdfFile = `data:application/pdf;base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  //FileName  => BILLERNAME_DD_MM_YYYY
  const receiptTitle = `${currentBillerName}_${new Date()
    .toLocaleDateString("en-GB")
    .split("/")
    .join("_")}`;
  downloadLink.href = pdfFile;
  downloadLink.download = receiptTitle;
  downloadLink.click();
};
