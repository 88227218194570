import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Payment/Selectors/CreditCardDetails.selector";

import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { DiscoveryApiData } from "~/src/Redux/Splash/Model/DiscoveryApi.model";

export type DiscoveryApiListState = {
  discoveryApiData: IRemoteData<DiscoveryApiData>;
};

const initialState: DiscoveryApiListState = {
  discoveryApiData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchDiscoverApiDetailsList(state) {
      state.discoveryApiData = state.discoveryApiData.setLoading();
    },
    fetchDiscoverApiDetailsListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.discoveryApiData = state.discoveryApiData.setError(action.payload);
    },
    fetchDiscoverApiDetailsListSuccess(
      state,
      action: PayloadAction<DiscoveryApiData>
    ) {
      state.discoveryApiData = state.discoveryApiData.setData(action.payload);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const discoveryApiDetailsSlice = createSlice(sliceOptions);

export default discoveryApiDetailsSlice.reducer;
export const {
  fetchDiscoverApiDetailsList,
  fetchDiscoverApiDetailsListFail,
  fetchDiscoverApiDetailsListSuccess,
} = discoveryApiDetailsSlice.actions;
