import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/CoachMarks/Selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import {
  CoachMarksData,
  ProcessCoachMarksData,
} from "~/src/Redux/CoachMarks/Model";

export type CoachMarksState = {
  coachMarksData: IRemoteData<CoachMarksData>;
  processCoachMarksData: IRemoteData<ProcessCoachMarksData>;
};

const initialState: CoachMarksState = {
  coachMarksData: new RemoteData(),
  processCoachMarksData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchCoachMarksData(state) {
      state.coachMarksData = state.coachMarksData.setLoading();
    },
    fetchCoachMarksFail(state, action: PayloadAction<ApiResponseError>) {
      state.coachMarksData = state.coachMarksData.setError(action.payload);
    },
    fetchCoachMarksSuccess(state, action: PayloadAction<CoachMarksData>) {
      state.coachMarksData = state.coachMarksData.setData(action.payload);
    },
    processCoachMarksData(state) {
      state.processCoachMarksData = state.processCoachMarksData.setLoading();
    },
    processCoachMarksFail(state, action: PayloadAction<ApiResponseError>) {
      state.processCoachMarksData = state.processCoachMarksData.setError(
        action.payload
      );
    },
    processCoachMarksSuccess(state, action: PayloadAction<CoachMarksData>) {
      state.processCoachMarksData = state.processCoachMarksData.setData(
        action.payload
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    // builder.addCase(getCategoryLogoAction, (state, { payload }: any) => {
    //   state.coachMarksData = payload
    // })
  },
};

const coachMarksSlice = createSlice(sliceOptions);

export default coachMarksSlice.reducer;
export const {
  fetchCoachMarksData,
  fetchCoachMarksSuccess,
  fetchCoachMarksFail,
  processCoachMarksData,
  processCoachMarksSuccess,
  processCoachMarksFail,
} = coachMarksSlice.actions;
