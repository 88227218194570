import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Billers/Selectors/CityList.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";

export type CityListState = {
  cityListData: IRemoteData<Array<string>>;
};

const initialState: CityListState = {
  cityListData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchCityLocationList(state) {
      state.cityListData = state.cityListData.setLoading();
    },
    fetchCityLocationListFail(state, action: PayloadAction<ApiResponseError>) {
      state.cityListData = state.cityListData.setError(action.payload);
    },
    fetchCityLocationListSuccess(state, action: PayloadAction<Array<string>>) {
      state.cityListData = state.cityListData.setData(action.payload);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const cityListSlice = createSlice(sliceOptions);

export default cityListSlice.reducer;
export const {
  fetchCityLocationList,
  fetchCityLocationListFail,
  fetchCityLocationListSuccess,
} = cityListSlice.actions;
