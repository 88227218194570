import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "./Faq.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { FaqData } from "./Faq.model";
import { ApiResponseError } from "~/src/Lib/types/api";

export type FaqListState = {
  faqList: IRemoteData<FaqData[]>;
};

const initialState: FaqListState = {
  faqList: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchFaqList(state) {
      state.faqList = state.faqList.setLoading();
    },
    fetchFaqListFail(state, action: PayloadAction<ApiResponseError>) {
      state.faqList = state.faqList.setError(action.payload);
    },
    fetchFaqListSuccess(state, action: PayloadAction<FaqData[]>) {
      state.faqList = state.faqList.setData(action.payload);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const faqListSlice = createSlice(sliceOptions);

export default faqListSlice.reducer;
export const { fetchFaqList, fetchFaqListSuccess, fetchFaqListFail } =
  faqListSlice.actions;
