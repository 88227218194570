import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "./Selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { PaymentDetail } from "../Payment/Model/PaymentDetail.model";

export type HandshakeState = {
  handshakeStatus: DataStatus;
};

const initialState: HandshakeState = {
  handshakeStatus: DataStatus.NOT_LOADED,
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    handshake(state) {
      //   state.handshakeStatus = state.handshakeStatus.setLoading();
      state.handshakeStatus = DataStatus.LOADING;
    },
    handshakeFail(state, action: PayloadAction<ApiResponseError>) {
      //   state.handshakeStatus = state.handshakeStatus.setError(action.payload);
      state.handshakeStatus = DataStatus.ERRORED;
    },
    handshakeSuccess(state, action: PayloadAction<PaymentDetail[]>) {
      //   state.historyDetailData = state.historyDetailData.setData(action.payload);
      state.handshakeStatus = DataStatus.LOADED;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    // builder.addCase(getCategoryLogoAction, (state, { payload }: any) => {
    //   state.categoryList = payload
    // })
  },
};

const handshakeSlice = createSlice(sliceOptions);

export default handshakeSlice.reducer;
export const { handshake, handshakeSuccess, handshakeFail } =
  handshakeSlice.actions;
