import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "cashbackTransactionDetailList";

const select = (state: any) => state[SLICE_NAME];
const getCashbackTransactionListSelect = (state: any) => state[SLICE_NAME];

export const getCashbackTransactionSelector = createSelector(
  getCashbackTransactionListSelect,
  (cashbackTransactionDetailList: any) => cashbackTransactionDetailList
);
