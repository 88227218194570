import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "discoveryApiList";

const select = (state: any) => state[SLICE_NAME];
const getDiscoveryApiListSelect = (state: any) => state[SLICE_NAME];

export const getDiscoveryApiSelector = createSelector(
  getDiscoveryApiListSelect,
  (discoveryApiList: any) => discoveryApiList
);
