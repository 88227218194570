import React, { lazy } from "react";
import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import { RouteObject, RouteProps } from "react-router-dom";
import { NavigateOptions, To } from "react-router-dom";

type CashbackRouteItem = {
  pathname: To;
  options?: NavigateOptions;
};

type CashbackRoute = {
  [key: string]: CashbackRouteItem;
};

const CashbackReceivedPage = lazy(
  () => import("~/src/Pages/Cashback/CashbackReceived.page")
);

const CashbackTransactionPage = lazy(
  () => import("~/src/Pages/Cashback/CashbackReceipt.page")
);

export const CASHBACK_ROUTES: CashbackRoute = {
  CASHBACK_RECEIVED: {
    pathname: `${APP_ROUTES.CASHBACK.pathname}/cashback-received`,
  },
  CASHBACK_STATUS: {
    pathname: `${APP_ROUTES.CASHBACK.pathname}/transaction-cashback-status`,
  },
};

export const cashbackRoutes: RouteObject[] = [
  {
    path: `${CASHBACK_ROUTES.CASHBACK_RECEIVED.pathname}`,
    element: <CashbackReceivedPage />,
  },

  {
    path: `${CASHBACK_ROUTES.CASHBACK_STATUS.pathname}`,
    element: <CashbackTransactionPage />,
  },
];
