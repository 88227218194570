import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "stopScheduledPaymentList";

const select = (state: any) => state[SLICE_NAME];
const getStopScheduledPaymentListSelect = (state: any) => state[SLICE_NAME];

export const getStopScheduledPaymentSelector = createSelector(
  getStopScheduledPaymentListSelect,
  (stopScheduledPaymentList: any) => stopScheduledPaymentList
);
