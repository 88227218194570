import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Prepaid/Selectors/PrepaidPlans.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { PlanDetail } from "../Model/prepaid.model";
export type PrepaidPlansListState = {
  prepaidPlansListData: IRemoteData<PlanDetail[]>;
};
const initialState: PrepaidPlansListState = {
  prepaidPlansListData: new RemoteData(),
};
const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchPrepaidPlansList(state) {
      state.prepaidPlansListData = state.prepaidPlansListData.setLoading();
    },
    fetchPrepaidPlansListFail(state, action: PayloadAction<ApiResponseError>) {
      state.prepaidPlansListData = state.prepaidPlansListData.setError(
        action.payload
      );
    },
    fetchPrepaidPlansListSuccess(state, action: PayloadAction<PlanDetail[]>) {
      state.prepaidPlansListData = state.prepaidPlansListData.setData(
        action.payload
      );
    },
    setPlanStatusNotLoaded(state) {
      state.prepaidPlansListData.status = DataStatus.NOT_LOADED;
      state.prepaidPlansListData = new RemoteData();
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};
const prepaidPlansListSlice = createSlice(sliceOptions);
export default prepaidPlansListSlice.reducer;
export const {
  fetchPrepaidPlansList,
  fetchPrepaidPlansListFail,
  fetchPrepaidPlansListSuccess,
  setPlanStatusNotLoaded,
} = prepaidPlansListSlice.actions;
