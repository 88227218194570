import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Payment/Selectors/StopScheduledPayment.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";

export type StopScheduledPaymentListState = {
  stopScheduledPaymentData: IRemoteData<string>;
};

const initialState: StopScheduledPaymentListState = {
  stopScheduledPaymentData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchStopScheduledPaymentList(state) {
      state.stopScheduledPaymentData =
        state.stopScheduledPaymentData.setLoading();
    },
    fetchStopScheduledPaymentListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.stopScheduledPaymentData = state.stopScheduledPaymentData =
        state.stopScheduledPaymentData.setError(action.payload);
    },
    fetchStopScheduledPaymentListSuccess(state, action: PayloadAction<string>) {
      state.stopScheduledPaymentData = state.stopScheduledPaymentData.setData(
        action.payload
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const stopScheduledPaymentSlice = createSlice(sliceOptions);

export default stopScheduledPaymentSlice.reducer;
export const {
  fetchStopScheduledPaymentList,
  fetchStopScheduledPaymentListFail,
  fetchStopScheduledPaymentListSuccess,
} = stopScheduledPaymentSlice.actions;
