import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "../Selectors/BillerAccount.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { BillerAccount } from "../Model/BillerAccount.model";
import { getBillerNickName } from "../Actions/BillerAccount.action";
import { getRegisterBillerDetailsListDataAction } from "../Actions/RegisteBiller.action";

export type BillerAccountStateType = {
  billerAccountData: IRemoteData<BillerAccount[]>;
  billerAccountStatus: DataStatus;
  billerAccountError?: ApiResponseError;
  currentBillerName: string;
  currentBillerCategory: string;
  //   downloadRecieptRequestParams: DownloadRecieptRequest;
  alreadyUsedNickNames: { [key: string]: number };
  registrationDetails: any[];
  cashbackBillerAccountData: any[];
};

const initialState: BillerAccountStateType = {
  billerAccountData: new RemoteData(),
  billerAccountStatus: DataStatus.NOT_LOADED,
  billerAccountError: undefined,
  currentBillerName: "",
  currentBillerCategory: "",
  cashbackBillerAccountData: [],
  registrationDetails: [],
  alreadyUsedNickNames: {},
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchDueBillsList(state) {
      state.billerAccountData = state.billerAccountData.setLoading();
      state.billerAccountStatus = state.billerAccountData.status;
    },
    fetchDueBillsListFail(state, action: PayloadAction<ApiResponseError>) {
      state.billerAccountData = state.billerAccountData.setError(
        action.payload
      );
      state.billerAccountStatus = state.billerAccountData.status;
    },
    fetchDueBillsListSuccess(state, action: PayloadAction<BillerAccount[]>) {
      state.billerAccountData = state.billerAccountData.setData(action.payload);
      state.billerAccountStatus = state.billerAccountData.status;
    },
    setCashbackBillerAccountData(state, action: PayloadAction) {
      state.cashbackBillerAccountData = action.payload;
    },
    setRegistrationDetails(state, action: PayloadAction) {
      state.registrationDetails = action.payload;
    },
    getBillerAlreadyUsedNickName(
      state,
      action: PayloadAction<BillerAccount[]>
    ) {
      const nickNameMap: { [key: string]: number } = {};
      action.payload.forEach((item) => {
        nickNameMap[item?.shortName?.toLowerCase() || ""] = 1;
      });
      state.alreadyUsedNickNames = nickNameMap;
    },
    setBillerAccountStatusNotLoaded(state) {
      state.billerAccountStatus = DataStatus.NOT_LOADED;
      state.billerAccountData.status = DataStatus.NOT_LOADED;
      state.billerAccountData.data = new RemoteData();
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getBillerNickName, (state, { payload }: any) => {
      // const nickNameMap: { [key: string]: number } = {};
      // payload.forEach((item) => {
      //   nickNameMap[item.shortName?.toLowerCase() || ""] = 1;
      // });
      state.alreadyUsedNickNames = payload;
    });
  },
};

const biilerAccountSlice = createSlice(sliceOptions);

export default biilerAccountSlice.reducer;
export const {
  fetchDueBillsList,
  fetchDueBillsListSuccess,
  fetchDueBillsListFail,
  setCashbackBillerAccountData,
  setRegistrationDetails,
  setBillerAccountStatusNotLoaded,
  getBillerAlreadyUsedNickName,
} = biilerAccountSlice.actions;
