import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "cityLocationList";

const select = (state: any) => state[SLICE_NAME];
const getCityLocationListSelect = (state: any) => state[SLICE_NAME];

export const getCityLocationSelector = createSelector(
  getCityLocationListSelect,
  (cityLocationList: any) => cityLocationList
);
