import {
  WEB_HTTP_CONTEXT,
  WEB_HTTP_REQ_HEADERS,
  WebHttpRequestOptions,
} from "@am92/web-http";
import { asHttp } from "~/src/Configurations/WebHttp";
import { RootState } from "~/src/Configurations/AppStore";
import { AS_API_DOMAIN } from "~/src/Configurations/env";
import { URL } from "~/src/Constants/API_URLS";

import { Action, ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";

import {
  handshake,
  handshakeFail,
  handshakeSuccess,
  HandshakeState,
} from "./Reducer";
import { AuthToken, AuthTokenFail, AuthTokenSuccess } from "../Auth/Reducer";
// import { CustomerDetail, CustomerDetailMapper } from "../Model";
type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  string,
  Action<string>
>;
const HANDSHAKE_URL = URL.AUTHENTICATION.HANDSHAKE;
export const handshakeThunk =
  (): AppThunk<Promise<any | ApiResponseError>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(handshake(""));
    // dispatch(AuthToken(""));
    try {
      const options: WebHttpRequestOptions = {
        url: HANDSHAKE_URL,
        method: "POST",
        data: {},
        webHttpConfig: {
          disableCrypto: true,
        },
      };

      const response = await asHttp.request(options);
      const { data: body, headers } = response;
      const { data } = body;
      const accessToken = headers["x-access-token"];

      const { token } = data;
      // const { accessToken = "", refreshToken = "" } = tokens;
      // webHttp.context.set(WEB_HTTP_CONTEXT.PUBLIC_KEY, publicKey);
      // asHttp.context.set(WEB_HTTP_CONTEXT.PUBLIC_KEY, publicKey);
      // asHttp.context.set(WEB_HTTP_CONTEXT.ACCESS_TOKEN, accessToken);
      // asHttp.context.set(
      //   WEB_HTTP_CONTEXT.ACCESS_TOKEN,
      //   WEB_HTTP_REQ_HEADERS.AUTH_TOKEN
      // );
      // asHttp.context.set(WEB_HTTP_CONTEXT.REFRESH_TOKEN, accessToken);
      // asHttp.context.set(WEB_HTTP_CONTEXT.PUBLIC_KEY, data?.publicKey);
      const tokens = {
        accessToken: accessToken,
        refreshToken: accessToken,
      };
      dispatch(handshakeSuccess(data));
      dispatch(AuthTokenSuccess(tokens));
      return data;
    } catch (error) {
      dispatch(handshakeFail(error as ApiResponseError));
      // dispatch(AuthTokenFail(error as ApiResponseError));
      throw error;
    }
  };
