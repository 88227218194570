import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Payment/Selectors/SendOtp.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";

export type SendOtpListState = {
  sendOtpListData: IRemoteData<{ refId: string }>;
};

const initialState: SendOtpListState = {
  sendOtpListData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchSendOtpList(state) {
      state.sendOtpListData = state.sendOtpListData.setLoading();
    },
    fetchSendOtpListFail(state, action: PayloadAction<ApiResponseError>) {
      state.sendOtpListData = state.sendOtpListData.setError(action.payload);
    },
    fetchSendOtpListSuccess(state, action: PayloadAction<{ refId: string }>) {
      state.sendOtpListData = state.sendOtpListData.setData(action.payload);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const sendOtpListSlice = createSlice(sliceOptions);

export default sendOtpListSlice.reducer;
export const {
  fetchSendOtpList,
  fetchSendOtpListFail,
  fetchSendOtpListSuccess,
} = sendOtpListSlice.actions;
