import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "milestonesDetailList";

const select = (state: any) => state[SLICE_NAME];
const getMilestoneDetailListSelect = (state: any) => state[SLICE_NAME];

export const getMilestonesSelector = createSelector(
  getMilestoneDetailListSelect,
  (milestonesDetailList: any) => milestonesDetailList
);
