import { CommonErrorComponent } from "~/src/Pages/common/error/CommonError.page";
import React, { Component, ErrorInfo } from "react";
// import { PrepaidService } from "modules/billers/service/PrepaidService";
// import { CustomerContext } from "modules/common/CustomerDetailProvider";

// const prepaidService = new PrepaidService();

export class ApplicationErrorBoundary extends Component<
  any,
  { hasError: boolean } // err: any
> {
  // static contextType = CustomerContext;
  constructor(props: any) {
    super(props);
    this.state = { hasError: false }; //err: {} as Error
  }

  static getDerivedStateFromError(error: any): { hasError: boolean } {
    console.group("ApplicationErrorBoundary");
    console.error(error);
    console.groupEnd();
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // prepaidService.printPrepaidLogs(
    //   "componentDidCatch Application Error Boundary",
    //   JSON.stringify(error.message + error.stack + error.name)
    // );
    console.group("ApplicationErrorBoundary ErrorInfo");
    console.error(error);
    // this.setState({ err: error?.message + error?.stack + error?.name });
    console.error(errorInfo);
    console.groupEnd();
  }

  // handleErrorPrint = () => {
  //   const custId = process.env.PREPAID_CUSTID || "";
  //   const isValidCustomerForLogs = custId
  //     .split(",")
  //     .includes(this.context.customerId)
  //     ? true
  //     : false;
  //   return isValidCustomerForLogs;
  // };

  render() {
    if (this.state.hasError) {
      return <CommonErrorComponent />;
      // return (
      //   <>
      //     <>{this.handleErrorPrint() && JSON.stringify(this.state.err)}</>
      //     <CommonErrorComponent />
      //   </>
      // );
    }
    return this.props.children;
  }
}
