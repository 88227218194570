import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "cashbackCustomerDetailList";

const select = (state: any) => state[SLICE_NAME];
const getCashbackCustomerDetailListSelect = (state: any) => state[SLICE_NAME];

export const getCashbackCustomerDetailSelector = createSelector(
  getCashbackCustomerDetailListSelect,
  (cashbackCustomerDetailList: any) => cashbackCustomerDetailList
);
