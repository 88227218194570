import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "cashbackActiveOfferList";

const select = (state: any) => state[SLICE_NAME];
const getCashbackActiveOfferListSelect = (state: any) => state[SLICE_NAME];

export const getCashbackActiveOfferSelector = createSelector(
  getCashbackActiveOfferListSelect,
  (cashbackActiveOfferList: any) => cashbackActiveOfferList
);
