import { createAction } from "@reduxjs/toolkit";

export const billerAccount = "billerAccount/data";

export const getCategoryListDataAction = createAction<any, string>(
  "billerAccount/data"
);

export const getBillerNickName = createAction<any, string>(
  "billerAccount/data"
);
