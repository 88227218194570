import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "validatePayment";

const select = (state: any) => state[SLICE_NAME];
const validatePaymentSelect = (state: any) => state[SLICE_NAME];

export const validatePaymentSelector = createSelector(
  validatePaymentSelect,
  (validatePayment: any) => validatePayment
);

export const validatePayment = createSelector(
  (state) => state[SLICE_NAME].validatePaymentData,
  (validatePaymentData) => validatePaymentData
);
