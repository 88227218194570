import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "categoryList";

const select = (state: any) => state[SLICE_NAME];
const getCategoryListSelect = (state: any) => state[SLICE_NAME];
const categoryListSelect = (state: any) => state[SLICE_NAME].categoryList;

export const getCategorySelector = createSelector(
  getCategoryListSelect,
  (category: any) => category
);

export const getCategoryLogo = createSelector(
  (state) => state[SLICE_NAME].categoryLogo,
  (categoryLogo) => categoryLogo
);
