import {
  CategoryHelperObject,
  ElementIneterface,
  homePageHelperObject,
} from "./googleAnalyticsInterface";

export const dataLayer: ElementIneterface[] = [];

// const format: ElementIneterface = {
//   event: "<event>",
//   category: "<category>",
//   action: "<action>",
//   label: "<label>",
//   page: "<page>",
//   title: "<title>",
//   cd2: "<<pass encrypted customer id>>",
//   cd24: "<<pass platform either website or internet-banking or mobile-app>>",
// };

export function excecuteGA(value: ElementIneterface): void {
  dataLayer.push(value);
}

export const handleGAPush = (
  category: string,
  action: string,
  label: string,
  page = "",
  title = "",
  event = "",
  actionData?: string
): void => {
  //Start GA push
  const gaObject: ElementIneterface = {
    event: event ? event.toLowerCase() : CategoryHelperObject.eventPayBills,
    category: category.toLowerCase(),
    action: actionData
      ? `${CategoryHelperObject.action + action.toLowerCase()} ${actionData}`
      : CategoryHelperObject.action + action.toLowerCase(),
    label: label.toLowerCase(),
    cd2: homePageHelperObject.cd2,
    cd24: homePageHelperObject.cd24,
    page,
    title,
  };
  // console.log("HELLO GA", window.dataLayer);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error explanation: window datalayer will be present Remove double slash from @ sign once GA needs to be activated
  //console.log(gaObject); // remove this once GA needs to be activated
  window.dataLayer.push(gaObject); // uncomment this once GA needs to be activated
  //dataLayer.push(gaObject);
  //end GA push
};
