import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "./Customer.selector";
import {
  getCustomerDataConfigAction,
  setCustomerIdConfigAction,
  setCustomerNameConfigAction,
  setChannelIdConfigAction,
} from "./Customer.action";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { CustomerDetail } from "./Model";
import { ApiResponseError } from "~/src/Lib/types/api";

export enum CustomerChannelEnum {
  InternetBanking = "InternetBanking" as any, //407
  MobileBanking = "MobileBanking" as any, //410
}

export type CustomerDetailState = {
  customerDetail: IRemoteData<CustomerDetail>;
  customerId: string;
  firstTimeAppLoad: boolean;
};

// interface StoreParams {
//   customerDetail: object
// }
const initDetail =
  process.env.NODE_ENV?.toLowerCase() === "development" &&
  process.env.IS_LOCAL?.toLowerCase() === "true"
    ? {
        customerId: "003000314", //864165876 //213025054 //900066016 //004002640 //003000314 //008003714
        mobile: "8107357801",
        channelId: CustomerChannelEnum.InternetBanking,
        emailId: "test@gmail.com",
        timestamp: new Date(),
        customerStatus: DataStatus.LOADED,
        maintenance: "false",
        accountSchemeCodes: [],
      }
    : {
        customerId: "",
        mobile: "",
        channelId: CustomerChannelEnum.InternetBanking,
        emailId: "",
        timestamp: new Date(),
        customerStatus: DataStatus.NOT_LOADED,
        maintenance: "",
        accountSchemeCodes: [],
      };
const initialState: CustomerDetailState = {
  customerDetail: new RemoteData(),
  customerId: "",
  firstTimeAppLoad: true,
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchCustomerDetail(state) {
      state.customerDetail = state.customerDetail.setLoading();
    },
    fetchCustomerDetailFail(state, action: PayloadAction<ApiResponseError>) {
      state.customerDetail = state.customerDetail.setError(action.payload);
    },
    fetchCustomerDetailSuccess(state, action: PayloadAction<CustomerDetail>) {
      state.customerDetail = state.customerDetail.setData(action.payload);
      state.customerId = action.payload.customerId;
    },
    setFirstTimeAppLoad(state, action: PayloadAction) {
      state.firstTimeAppLoad = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getCustomerDataConfigAction, (state, { payload }: any) => {
      state.customerDetail = payload;
    });
    builder.addCase(setChannelIdConfigAction, (state, { payload }: any) => {
      state.customerDetail.channelId = payload;
    });
    builder.addCase(setCustomerNameConfigAction, (state, { payload }: any) => {
      state.user.name = payload;
    });
    builder.addCase(setCustomerIdConfigAction, (state, { payload }: any) => {
      state.customerDetail.customerId = payload;
    });
  },
};

const customerSlice = createSlice(sliceOptions);

export default customerSlice.reducer;
export const {
  fetchCustomerDetail,
  fetchCustomerDetailSuccess,
  fetchCustomerDetailFail,
  setFirstTimeAppLoad,
} = customerSlice.actions;
