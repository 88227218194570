import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Cashback/Selectors/RecentCashbackDetail.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";

import { RecentCashbackDetailData } from "~/src/Redux/Cashback/Model/recentCashbackDetail.model";

export type RecentCashbackDetailListState = {
  recentCashbackData: IRemoteData<RecentCashbackDetailData[]>;
};

const initialState: RecentCashbackDetailListState = {
  recentCashbackData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchRecentCashbackList(state) {
      state.recentCashbackData = state.recentCashbackData.setLoading();
    },
    fetchRecentCashbackListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.recentCashbackData = state.recentCashbackData.setError(
        action.payload
      );
    },
    fetchRecentCashbackListSuccess(
      state,
      action: PayloadAction<RecentCashbackDetailData[]>
    ) {
      state.recentCashbackData = state.recentCashbackData.setData(
        action.payload
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const recentCashbackDetailSlice = createSlice(sliceOptions);

export default recentCashbackDetailSlice.reducer;
export const {
  fetchRecentCashbackList,
  fetchRecentCashbackListFail,
  fetchRecentCashbackListSuccess,
} = recentCashbackDetailSlice.actions;
