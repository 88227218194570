import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Prepaid/Selectors/PrepaidCircle.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { Circle, CircleMapper } from "../Model/prepaid.model";
export type PrepaidCircleListState = {
  prepaidCircleListData: IRemoteData<Circle[]>;
};
const initialState: PrepaidCircleListState = {
  prepaidCircleListData: new RemoteData(),
};
const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchPrepaidCircleList(state) {
      state.prepaidCircleListData = state.prepaidCircleListData.setLoading();
    },
    fetchPrepaidCircleListFail(state, action: PayloadAction<ApiResponseError>) {
      state.prepaidCircleListData = state.prepaidCircleListData.setError(
        action.payload
      );
    },
    fetchPrepaidCircleListSuccess(state, action: PayloadAction<Circle[]>) {
      state.prepaidCircleListData = state.prepaidCircleListData.setData(
        action.payload
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};
const prepaidCircleListSlice = createSlice(sliceOptions);
export default prepaidCircleListSlice.reducer;
export const {
  fetchPrepaidCircleList,
  fetchPrepaidCircleListFail,
  fetchPrepaidCircleListSuccess,
} = prepaidCircleListSlice.actions;
