import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "deleteRegisterBillerList";

const select = (state: any) => state[SLICE_NAME];
const getDeleteRegisterBillerListSelect = (state: any) => state[SLICE_NAME];

export const getDeleteRegisterBillerSelector = createSelector(
  getDeleteRegisterBillerListSelect,
  (deleteRegisterBillerList: any) => deleteRegisterBillerList
);
