import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "sendOtp";

const select = (state: any) => state[SLICE_NAME];
const getSendOtpListSelect = (state: any) => state[SLICE_NAME];

export const getSendOtpSelector = createSelector(
  getSendOtpListSelect,
  (sendOtpListData: any) => sendOtpListData
);

// export const getSendOtpRefId = createSelector(
//   getSendOtpListSelect,
//   (sendOtpListData: any) => sendOtpListData.refId
// );
