import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "../Selectors/RegisterBiller.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { BillerAccount } from "../Model/BillerAccount.model";

export type RegisterBillerDetailsListState = {
  registerBillerData: IRemoteData<BillerAccount>;
};

const initialState: RegisterBillerDetailsListState = {
  registerBillerData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchRegisterBillerList(state) {
      state.registerBillerData = state.registerBillerData.setLoading();
    },
    fetchRegisterBillerListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.registerBillerData = state.registerBillerData =
        state.registerBillerData.setError(action.payload);
    },
    fetchRegisterBillerListSuccess(
      state,
      action: PayloadAction<BillerAccount>
    ) {
      state.registerBillerData = state.registerBillerData.setData(
        action.payload
      );
    },
    setBillerRegisterStatusNotLoaded(state) {
      state.registerBillerData.status = DataStatus.NOT_LOADED;
      state.registerBillerData = new RemoteData();
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const registerBillerDetailsSlice = createSlice(sliceOptions);

export default registerBillerDetailsSlice.reducer;
export const {
  fetchRegisterBillerList,
  fetchRegisterBillerListFail,
  fetchRegisterBillerListSuccess,
  setBillerRegisterStatusNotLoaded,
} = registerBillerDetailsSlice.actions;
