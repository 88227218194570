import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "pastTicketsList";

const select = (state: any) => state[SLICE_NAME];
const getPastTicketsListSelect = (state: any) => state[SLICE_NAME];

export const getPastTicketsSelector = createSelector(
  getPastTicketsListSelect,
  (pastTicketsList: any) => pastTicketsList
);
