import React from "react";
import withRouter, { IWithRouterProps } from "../Lib/withRouter";
import { connect } from "react-redux";
import {
  DsBox,
  DsMenu,
  DsMenuItem,
  DsRemixIcon,
  enqueueNotistack,
} from "@am92/react-design-system";
import APP_ROUTES from "../Constants/APP_ROUTES";
import { RootState } from "../Configurations/AppStore";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { DownloadRecieptRequest } from "../Redux/Payment/Model/DownloadReceipt.model";
import { downloadReceiptThunk } from "../Redux/Payment/Services/downloadReciept.service";
import {
  downloadReceiptSelect,
  downloadReceiptSelector,
  getdownloadReceiptRequest,
} from "../Redux/Payment/Selectors/DownloadReceipt.selector";
import { DownloadReceiptState } from "../Redux/Payment/Reducers/DownloadReceipt.reducer";
import { handleGAPush } from "../Common/googleAnalytics/googleAnalytics";
import { CategoryHelperObject } from "../Common/googleAnalytics/googleAnalyticsInterface";
import { getCustomerData } from "../Redux/Customer/Customer.selector";
import { CustomerDetail } from "../Redux/Customer/Model";
import { downloadRecieptHelperFunction } from "../Pages/common/downloadReceipt/downloadReceiptHelper";
import { DataStatus } from "../Lib/types/datatransfer";
import { CustomerChannelEnum } from "../Redux/Customer/Customer.reducer";

type PaymentNavRightSectionActions = {
  downloadReceipt: (request: DownloadRecieptRequest) => void;
};
interface PaymentNavRightSectionProps extends IWithRouterProps {
  actions: PaymentNavRightSectionActions;
  downloadRecieptRequest: DownloadRecieptRequest;
  customerData: CustomerDetail;
  downloadReceiptData: DownloadReceiptState;
}
interface PaymentNavRightSectionState {
  anchorEl: HTMLElement | null;
  toastOpen: boolean;
}
class PaymentNavRightSectionPage extends React.Component<
  PaymentNavRightSectionProps,
  PaymentNavRightSectionState
> {
  constructor(props: PaymentNavRightSectionProps) {
    super(props);
    this.state = {
      anchorEl: null,
      toastOpen: false,
    };
  }
  componentDidUpdate(
    prevProps: PaymentNavRightSectionProps,
    prevState: PaymentNavRightSectionState
  ): void {
    const {
      downloadReceiptData: {
        downloadReceiptData,
        currentBillerName,
        currentBillerCategory,
      },
    } = this.props;
    if (
      prevProps.downloadReceiptData.downloadReceiptData.status !==
      downloadReceiptData.status
    )
      if (
        downloadReceiptData.status === DataStatus.LOADED &&
        downloadReceiptData &&
        downloadReceiptData.data &&
        downloadReceiptData.data.pdf
      ) {
        downloadRecieptHelperFunction(
          downloadReceiptData.data.pdf,
          currentBillerName
        );
        enqueueNotistack({
          message: "Payment receipt downloaded!",
          variant: "default",
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          style: { paddingBottom: "var(--ds-spacing-cool)" },
        });
      }
  }

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleDownload = () => {
    const {
      actions: { downloadReceipt },
      downloadRecieptRequest,
      downloadReceiptData: { currentBillerCategory },
    } = this.props;
    handleGAPush(
      CategoryHelperObject.categoryPayBills +
        currentBillerCategory.toLowerCase(),
      `${currentBillerCategory.toLowerCase()} | payment reciept`,
      `download reciept`
    );
    downloadReceipt(downloadRecieptRequest);
  };

  render(): React.ReactNode {
    const { anchorEl } = this.state;
    const {
      navigate,
      customerData: { channelId },
    } = this.props;
    const open = Boolean(this.state.anchorEl);
    return (
      <>
        <DsBox
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            padding: "var(--ds-spacing-bitterCold)",
          }}
        >
          <DsRemixIcon
            className="ri-more-2-fill"
            onClick={this.handleClick}
            // size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          />
        </DsBox>
        <DsMenu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={this.handleClose}
          onClick={this.handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {channelId !== CustomerChannelEnum.MobileBanking && (
            <DsMenuItem onClick={this.handleDownload}>
              Download Receipt
            </DsMenuItem>
          )}

          <DsMenuItem onClick={() => navigate(APP_ROUTES.SUPPORT.pathname)}>
            Support
          </DsMenuItem>
        </DsMenu>
      </>
    );
  }
}
const mapStateToProps = (
  state: RootState & {
    // downloadReceipt: DownloadReceiptState;
    customerData: CustomerDetail;
  }
) => {
  const downloadRecieptRequest = getdownloadReceiptRequest(state);
  const customerData = getCustomerData(state);
  const downloadReceiptData = downloadReceiptSelector(state);

  return {
    downloadRecieptRequest,
    customerData,
    downloadReceiptData,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  actions: {
    downloadReceipt: (downloadReceiptRequest: DownloadRecieptRequest) =>
      dispatch(downloadReceiptThunk(downloadReceiptRequest)),
  },
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentNavRightSectionPage)
);
