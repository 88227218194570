import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "faqList";

const select = (state: any) => state[SLICE_NAME];
const getFaqListSelect = (state: any) => state[SLICE_NAME];

export const getFaqSelector = createSelector(
  getFaqListSelect,
  (faq: any) => faq
);
