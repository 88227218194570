import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "registerBillerDetailsList";

const select = (state: any) => state[SLICE_NAME];
const getRegisterBillerDetailsListSelect = (state: any) => state[SLICE_NAME];

export const getRegisterBillerDetailsListSelector = createSelector(
  getRegisterBillerDetailsListSelect,
  (registerBillerDetailsList: any) => registerBillerDetailsList
);
