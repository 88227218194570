import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "../Selectors/BillerAccount.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { Biller } from "../Model/Biller.model";

export type BillerDataStateType = {
  billers: IRemoteData<Biller[]>;
  billerData: Biller[];
  billerStatus: DataStatus;
  billerError?: ApiResponseError;
};

const initialState: BillerDataStateType = {
  billers: new RemoteData(),
  billerData: [],
  billerStatus: DataStatus.NOT_LOADED,
  billerError: undefined,
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchBillerByCategory(state) {
      state.billers = state.billers.setLoading();
      state.billerStatus = state.billers.status;
    },
    fetchBillerByCategoryFail(state, action: PayloadAction<ApiResponseError>) {
      state.billers = state.billers.setError(action.payload);
      state.billerError = action.payload;
      state.billerStatus = state.billers.status;
    },
    fetchBillerByCategorySuccess(state, action: PayloadAction<Biller[]>) {
      state.billers = state.billers.setData(action.payload);
      state.billerData = action.payload;
      state.billerStatus = state.billers.status;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const billerData = createSlice(sliceOptions);

export default billerData.reducer;
export const {
  fetchBillerByCategory,
  fetchBillerByCategorySuccess,
  fetchBillerByCategoryFail,
} = billerData.actions;
