import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Billers/Selectors/ModifyRegisteredBiller.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { DeleteAndModifyBillerInfo } from "../Model/RegisteredBiller.model";

export type ModifyRegisteredBillerListState = {
  modifyRegisteredBillerData: IRemoteData<DeleteAndModifyBillerInfo>;
};

const initialState: ModifyRegisteredBillerListState = {
  modifyRegisteredBillerData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchModifyRegisteredBillerList(state) {
      state.modifyRegisteredBillerData =
        state.modifyRegisteredBillerData.setLoading();
    },
    fetchModifyRegisteredBillerListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.modifyRegisteredBillerData =
        state.modifyRegisteredBillerData.setError(action.payload);
    },
    fetchModifyRegisteredBillerListSuccess(
      state,
      action: PayloadAction<DeleteAndModifyBillerInfo>
    ) {
      state.modifyRegisteredBillerData =
        state.modifyRegisteredBillerData.setData(action.payload);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const modifyRegisteredBillerSlice = createSlice(sliceOptions);

export default modifyRegisteredBillerSlice.reducer;
export const {
  fetchModifyRegisteredBillerList,
  fetchModifyRegisteredBillerListFail,
  fetchModifyRegisteredBillerListSuccess,
} = modifyRegisteredBillerSlice.actions;
