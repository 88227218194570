import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "cashbackTermsConditionsList";

const select = (state: any) => state[SLICE_NAME];
const getTermsConditionsListSelect = (state: any) => state[SLICE_NAME];

export const getTermsConditionsSelector = createSelector(
  getTermsConditionsListSelect,
  (cashbackTermsConditionsList: any) => cashbackTermsConditionsList
);
