import React, { Component, ReactElement } from "react";
import {
  DsImage,
  DsBox,
  DsTypography,
  DsStack,
} from "@am92/react-design-system";
import LoaderAnimation from "./LoaderAnimation";

type OverlayLoaderProps = {
  bg?: string;
  loaderText?: {
    heading: string;
    subText: string;
  };
};

// const axisLoaderGif = {
//   src: new URL('~/public/assets/images/loader/loading.gif', import.meta.url)
//     .href,
//   alt: 'Axis Loader',
//   as: 'image/gif'
// }
class OverlayLoader extends Component<OverlayLoaderProps> {
  render(): ReactElement {
    const { bg, loaderText } = this.props;
    return (
      <>
        {
          <DsBox
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            margin="auto"
            bgcolor={bg ? bg : "var(--ds-colour-surfaceBackground)"}
            sx={{
              width: "100%",
              position: "fixed",
              zIndex: 1,
              top: 0,
              left: 0,
            }}
          >
            <DsStack>
              <LoaderAnimation />
              {loaderText && (
                <DsStack textAlign={"center"} gap={2}>
                  <DsTypography variant="headingBoldSmall">
                    {loaderText?.heading}
                  </DsTypography>
                  <DsTypography variant="bodyRegularMedium">
                    {loaderText?.subText}
                  </DsTypography>
                </DsStack>
              )}
            </DsStack>
          </DsBox>
        }
      </>
    );
  }
}

export default OverlayLoader;
export type WithLoaderProps = {
  setLoading: (isLoading: boolean) => void;
};
// const withLoader = <Props extends Record<string, unknown> | undefined>(
//   WrappedComponent: React.ComponentType<Props & WithLoaderProps>
// ) =>
//   class LoaderHoc extends Component<Props> {
//     state = {
//       isLoading: false,
//     };
//     setLoading = (isLoading: boolean) => {
//       this.setState({ isLoading });
//     };
//     render(): ReactElement {
//       return (
//         <>
//           <WrappedComponent {...this.props} setLoading={this.setLoading} />
//           {this.state.isLoading ? <OverlayLoader /> : null}
//         </>
//       );
//     }
//   };
// export default withLoader;
