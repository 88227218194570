import React, { useEffect } from "react";
import {
  DsBox,
  DsButton,
  DsImage,
  DsImageProps,
} from "@am92/react-design-system";
import { handleGAPush } from "~/src/Common/googleAnalytics/googleAnalytics";
import { CategoryHelperObject } from "~/src/Common/googleAnalytics/googleAnalyticsInterface";
import { ErrorLayout } from "~/src/components/ErrorLayout";
import { useNavigate } from "react-router-dom";
import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import images from "~/src/Constants/images";
import { withNavigationBar } from "~/src/components/NavigationBar";

export const CommonErrorComponent = () => {
  useEffect(() => {
    handleGAPush(
      CategoryHelperObject.categoryPayBills + "error",
      "Error",
      "Experiencing technical difficulties"
    );
  }, []);

  const navigate = useNavigate();
  return (
    <ErrorLayout
      errorIcon={
        <DsBox display="flex" alignItems="center" justifyContent="center">
          <DsImage
            srcSet={ErrorImage}
            style={{
              width: "148px",
              height: "148px",
            }}
            // style={{
            //   marginTop: "max(6vh, 2rem)",
            // }}
          />
        </DsBox>
      }
      title="Something went wrong"
      description="We are experiencing some difficulties. Please try again later."
      bottomStickerRender={
        <DsBox>
          <DsButton
            sx={{ width: "100%" }}
            size="large"
            onClick={() => navigate(APP_ROUTES.HOME.pathname)}
          >
            Home
          </DsButton>
        </DsBox>
      }
    />
  );
};

export default withNavigationBar(CommonErrorComponent)(
  "",
  <></>,
  () => void (<></>),
  <></>
);

const ErrorImage: DsImageProps["srcSet"] = [
  {
    src: new URL(`${images.DeleteBillerSBZ}`, import.meta.url).href,
    alt: "Something went wrong Image",
    // as: "image/png",
  },
];
