import { WEB_HTTP_CONTEXT, WebHttpRequestOptions } from "@am92/web-http";
import { asHttp } from "~/src/Configurations/WebHttp";
import { RootState } from "~/src/Configurations/AppStore";
import { AS_API_DOMAIN } from "~/src/Configurations/env";
import { URL } from "~/src/Constants/API_URLS";

import { Action, ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";

import { DownloadRecieptResponseDto } from "~/src/Redux/Payment/Model/DownloadReceipt.model";

import {
  fetchDownloadReceipt,
  fetchDownloadReceiptFail,
  fetchDownloadReceiptSuccess,
} from "../Reducers/DownloadReceipt.reducer";
import { DownloadRecieptRequest } from "../Model/DownloadReceipt.model";

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const DOWNLOAD_RECIEPT_URL = URL.PAYMENT.DOWNLOAD_RECIEPT;

export const downloadReceiptThunk =
  (
    request: DownloadRecieptRequest
  ): AppThunk<Promise<any | ApiResponseError>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(fetchDownloadReceipt(request));
    console.log("request requestrequestrequest", request);
    try {
      const options: WebHttpRequestOptions = {
        url: DOWNLOAD_RECIEPT_URL,
        method: "POST",
        data: request,
      };

      const response = await asHttp.request(options);
      const { data } = response;
      const { tokens = {} } = data;
      const token =
        // Instore.get('auth') ||
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjA3YjUzMWM1LWE5YzAtNGJhMS05NzlhLWQ1ZDVjMTQ1MmU0YiIsImlhdCI6MTY1NDg2MDg5NzkyNX0.rKAXRZIvOUed22lbPesnFoXHzaYNTLkRNtpGvJvBvCY";

      /*       const { accessToken = token, refreshToken = "" } = tokens;
      asHttp.context.set(WEB_HTTP_CONTEXT.ACCESS_TOKEN, accessToken);
      asHttp.context.set(WEB_HTTP_CONTEXT.REFRESH_TOKEN, refreshToken); */
      const mappedData = response.data.data;
      dispatch(fetchDownloadReceiptSuccess(mappedData));
      console.log(mappedData, "data acc details", response.data.data);
      return mappedData;
    } catch (error) {
      dispatch(fetchDownloadReceiptFail(error as ApiResponseError));
      throw error;
    }
  };
