import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "validateOtp";

const select = (state: any) => state[SLICE_NAME];
const getValidateOtpListSelect = (state: any) => state[SLICE_NAME];

export const getValidateOtpSelector = createSelector(
  getValidateOtpListSelect,
  (validateOtpList: any) => validateOtpList
);
