import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "../Selectors/ValidateBill.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { ValidatePayment } from "../Model/ValidatePayment.model";

export type ValidateBillStateType = {
  validateData: IRemoteData<ValidatePayment>;
  validateBillData: ValidatePayment;
  validateBillStatus: DataStatus;
  validateBillError?: ApiResponseError;
};

const initialState: ValidateBillStateType = {
  validateData: new RemoteData(),
  validateBillData: {} as ValidatePayment,
  validateBillStatus: DataStatus.NOT_LOADED,
  validateBillError: undefined,
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchValidateBill(state) {
      state.validateData = state.validateData.setLoading();
      state.validateBillStatus = state.validateData.status;
    },
    fetchValidateBillFail(state, action: PayloadAction<ApiResponseError>) {
      state.validateData = state.validateData.setError(action.payload);
      state.validateBillError = action.payload;
      state.validateBillStatus = state.validateData.status;
    },
    fetchValidateBillSuccess(state, action: PayloadAction<ValidatePayment>) {
      state.validateData = state.validateData.setData(action.payload);
      state.validateBillData = action.payload;
      state.validateBillStatus = state.validateData.status;
      state.validateBillError = undefined;
    },
    setValidateBillStatusNotLoaded(state) {
      state.validateData = new RemoteData();
      state.validateBillData = {} as ValidatePayment;
      state.validateBillStatus = DataStatus.NOT_LOADED;
      state.validateBillError = undefined;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const validateBill = createSlice(sliceOptions);

export default validateBill.reducer;
export const {
  fetchValidateBill,
  fetchValidateBillSuccess,
  fetchValidateBillFail,
  setValidateBillStatusNotLoaded,
} = validateBill.actions;
