import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Support/Selectors/RaiseComplaint.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { ComplaintStatusData } from "../Model/ComplaintStatus.model";

export type ComplaintStatusListState = {
  complaintStatusDetailData: IRemoteData<ComplaintStatusData>;
};

const initialState: ComplaintStatusListState = {
  complaintStatusDetailData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchComplaintStatusList(state) {
      state.complaintStatusDetailData =
        state.complaintStatusDetailData.setLoading();
    },
    fetchComplaintStatusListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.complaintStatusDetailData =
        state.complaintStatusDetailData.setError(action.payload);
    },
    fetchComplaintStatusListSuccess(
      state,
      action: PayloadAction<ComplaintStatusData>
    ) {
      state.complaintStatusDetailData = state.complaintStatusDetailData.setData(
        action.payload
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const complaintStatusSlice = createSlice(sliceOptions);

export default complaintStatusSlice.reducer;
export const {
  fetchComplaintStatusList,
  fetchComplaintStatusListFail,
  fetchComplaintStatusListSuccess,
} = complaintStatusSlice.actions;
