import React, { lazy } from "react";
import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import { RouteObject, RouteProps } from "react-router-dom";
import { NavigateOptions, To } from "react-router-dom";

type BillerRouteItem = {
  pathname: To;
  options?: NavigateOptions;
};

type BillerRoute = {
  [key: string]: BillerRouteItem;
};

// // Category: Electricity
const ElectricityBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/electricity/electricity.page" /* webpackChunkName: "ElectricityBillerPage" */
    )
);

// Category: Mobile Postpaid
const MobilePostpaidBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/postpaid/postpaid.page" /* webpackChunkName: "MobilePostpaidBillerPage" */
    )
);

// Category: MutualFund
const MutualfundBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/mutualfund/mutualfund.page" /* webpackChunkName: "MutualfundBillerPage" */
    )
);
const MutualfundRegisterPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/mutualfund/mutualfund-register.page" /* webpackChunkName: "MutualfundRegisterPage" */
    )
);
const MutualfundLinkPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/mutualfund/mutualfund-link.page" /* webpackChunkName: "MutualfundLinkPage" */
    )
);
const MutualfundMPINPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/mutualfund/mutualfund-mpin.page" /* webpackChunkName: "MutualfundMPINPage" */
    )
);

const MutualfundStatus = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/mutualfund/mutualfund-status.page" /* webpackChunkName: "MutualFundStatusPage" */
    )
);

// // Category: MobilePrepaid
const MobilePrepaidPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/prepaid/prepaid.page" /* webpackChunkName: "MobilePrepaidPage" */
    )
);
const MobilePrepaidRegisterPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/prepaid/prepaid-register.page" /* webpackChunkName: "MobilePrepaidRegisterPage" */
    )
);
const MobilePrepaidPaymentPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/prepaid/prepaid-bill.page" /* webpackChunkName: "MobilePrepaidPaymentPage" */
    )
);

// // Category: CreditCard
const CreditCardBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/creditcard-new/creditcard.page" /* webpackChunkName: "CreditcardBillerPage" */
    )
);
const CreditCardRegisterPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/creditcard-new/creditcard-register.page" /* webpackChunkName: "CreditCardRegisterPage" */
    )
);

// Category: FastTag
const FasTagBillerPage = React.lazy(
  () => import("~/src/Pages/billers/category/fastag/fastag.page")
);

// // Category: Education
const EducationBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/education/education.page" /* webpackChunkName: "EducationPage" */
    )
);

// Category: Loan
const LoanBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/loan/loan.page" /* webpackChunkName: "LoanBillerPage" */
    )
);

// // Category: Broadband
const BroadbandBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/broadband/broadband.page" /* webpackChunkName: "BroadbandBillerPage" */
    )
);

// // Category: Landline
const LandlineBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/landline/landline.page" /* webpackChunkName: "LandlineBillerPage" */
    )
);

// Category: PipedGas
const PipedGasBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/pipedgas/pipedgas.page" /* webpackChunkName: "PipedGasBillerPage" */
    )
);

// Category: Insurance
const InsuranceBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/insurance/insurance.page" /* webpackChunkName: "InsuranceBillerPage" */
    )
);

// Category: Water
const WaterBillerPage = React.lazy(
  () => import("~/src/Pages/billers/category/water/water.page")
);

// Category: CableTV
const CableTVBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/cable-tv/cable-tv.page" /* webpackChunkName: "CableTVBillerPage" */
    )
);

// Category: Rental Payment
const RentalPaymentBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/rental-payment/rental-payment.page" /* webpackChunkName: "RentalPaymentBillerPage" */
    )
);

// // Category: DTH
const DthBillerPage = lazy(
  () => import("~/src/Pages/billers/category/dth/dth.page")
);

// Category: Gas
const GasBillerPage = lazy(
  () => import("~/src/Pages/billers/category/gas/gas.page")
);

// Category: Charity
const CharityBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/donation/donation.page"
    ) /* webpackChunkName: "CharityBillerPage" */
);

// Category: Recurring Deposit
const RecurringDepositBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/recurring-deposit/recurring-deposit.page"
    ) /* webpackChunkName: "RecurringDepositBillerPage" */
);

// Category: Municipal Tax
const MunicipalTaxBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/municipal-tax/municipal-tax.page" /* webpackChunkName: "MunicipalTaxBillerPage" */
    )
);

// Category: Municipal Services
const MunicipalServicesBillerPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/municipal-services/municipal-services.page" /* webpackChunkName: "MunicipalServicesBillerPage" */
    )
);

// Category: Subscription
const SubscriptionBillerPage = lazy(
  () => import("~/src/Pages/billers/category/subscription/subscription.page")
);

// Category: Housing Society
const HousingSocietyBillerPage = lazy(
  () =>
    import("~/src/Pages/billers/category/housing-society/housing-society.page")
);

// Category: Hospital
const Hospital = lazy(
  () => import("~/src/Pages/billers/category/hospital/hospital.page")
);

// Category: Clubs and Associations
const Clubs = lazy(
  () => import("~/src/Pages/billers/category/clubs/clubs.page")
);

// Category: NCMC
const NcmcPage = lazy(
  () => import("~/src/Pages/billers/category/ncmc/ncmc.page")
);

const NcmcPaymentPage = lazy(
  () => import("~/src/Pages/billers/category/ncmc/ncmc-payment.page")
);

// Category: NPS
const NpsPage = lazy(() => import("~/src/Pages/billers/category/nps/nps.page"));

// // Category: Common
// const BillerHomePage = lazy(
//   () =>
//     import(
//       "modules/billers/category/common/biller.home.page" /* webpackChunkName: "BillerHomePage" */
//     )
// );
const BillerRegisterPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/category/common/biller.register.page" /* webpackChunkName: "BillerRegisterPage" */
    )
);
//need to move the route to biller.billdetail.page
const BillerBillDetailPage = lazy(
  () =>
    import(
      "~/src/Pages/billers/biller.billdetail.page" /* webpackChunkName: "BillerBillDetailPage" */
    )
);

const PrepaidMeterPage = lazy(
  () => import("~/src/Pages/billers/category/prepaid-meter/prepaid-meter.page")
);

export const BILLERS_ROUTES: BillerRoute = {
  ELECTRICITY: { pathname: `${APP_ROUTES.BILLERS.pathname}/electricity` },
  CREDITCARD: { pathname: `${APP_ROUTES.BILLERS.pathname}/creditcard` },
  CREDITCARD_REGISTER: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/creditcard/register`,
  },
  FASTAG: { pathname: `${APP_ROUTES.BILLERS.pathname}/fastag` },

  EDUCATION: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/education`,
  },

  DTH: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/dth`,
  },

  GAS: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/gas`,
  },

  LOAN: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/loan`,
  },

  BROADBAND: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/broadband`,
  },

  LANDLINE: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/landline`,
  },

  PIPEDGAS: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/piped-gas`,
  },

  INSURANCE: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/insurance`,
  },

  WATER: { pathname: `${APP_ROUTES.BILLERS.pathname}/water` },

  CABLE_TV: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/cable-tv`,
  },

  RENTAL_PAYMENT: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/rental-payment`,
  },

  MOBILE_POSTPAID: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/mobile_postpaid`,
  },

  MUNICIPAL_TAX: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/tax`,
  },

  MUNICIPAL_SERVICES: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/municipal-services`,
  },

  RECURRING_DEPOSIT: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/recurring-deposit`,
  },

  // HEALTH_INSURANCE: `${APP_ROUTES.BILLERS}/health-insurance`,

  CHARITY: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/donation`,
  },

  CLUBS: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/clubs`,
  },

  MUTUALFUND: { pathname: `${APP_ROUTES.BILLERS.pathname}/mutualfund` },
  MUTUALFUND_REGISTER: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/mutualfund/register`,
  },
  MUTUALFUND_LINK: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/mutualfund/link`,
  },
  MUTUALFUND_STATUS: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/mutualfund/status`,
  },
  MUTUALFUND_MPIN: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/mutualfund/mpin`,
  },

  MOBILE_PREPAID: { pathname: `${APP_ROUTES.BILLERS.pathname}/mobile_prepaid` },
  MOBILE_PREPAID_REGISTER: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/mobile_prepaid/register`,
  },
  MOBILE_PREPAID_PAYMENT: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/mobile_prepaid/payment-detail`,
  },

  SUBSCRIPTION: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/subscription`,
  },

  HOUSING_SOCIETY: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/housing-society`,
  },

  HOSPITAL: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/hospital`,
  },

  NCMC: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/ncmc`,
  },

  NCMC_PAYMENT_PAGE: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/ncmc/payment`,
  },

  NPS: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/nps`,
  },

  // BILLER_HOME: `${APP_ROUTES.BILLERS}/common/home/:categoryId`,
  BILLER_REGISTER: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/common/register`,
  },
  // BILLER_REGISTER: `${APP_ROUTES.BILLERS.pathname}/common/register`,
  BILLER_BILLDETAIL: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/common/billdetail`,
  },
  PREPAID_METER: {
    pathname: `${APP_ROUTES.BILLERS.pathname}/prepaid-meter`,
  },
};
export const billerRoutes: RouteObject[] = [
  {
    path: `${BILLERS_ROUTES.WATER.pathname}`,
    element: <WaterBillerPage />,
  },
  {
    path: `${BILLERS_ROUTES.ELECTRICITY.pathname}`,
    element: <ElectricityBillerPage />,
  },
  {
    path: `${BILLERS_ROUTES.FASTAG.pathname}`,
    element: <FasTagBillerPage />,
  },
  {
    path: `${BILLERS_ROUTES.BILLER_REGISTER.pathname}`,
    element: <BillerRegisterPage />,
  },
  {
    path: `${BILLERS_ROUTES.BILLER_BILLDETAIL.pathname}`,
    element: <BillerBillDetailPage />,
  },
  {
    path: `${BILLERS_ROUTES.MOBILE_PREPAID.pathname}`,
    element: <MobilePrepaidPage />,
  },

  {
    path: `${BILLERS_ROUTES.MUTUALFUND.pathname}`,
    element: <MutualfundBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.MUTUALFUND_REGISTER.pathname}`,
    element: <MutualfundRegisterPage />,
  },

  {
    path: `${BILLERS_ROUTES.MUTUALFUND_LINK.pathname}`,
    element: <MutualfundLinkPage />,
  },

  {
    path: `${BILLERS_ROUTES.MUTUALFUND_MPIN.pathname}`,
    element: <MutualfundMPINPage />,
  },

  {
    path: `${BILLERS_ROUTES.MUTUALFUND_STATUS.pathname}`,
    element: <MutualfundStatus />,
  },

  {
    path: `${BILLERS_ROUTES.EDUCATION.pathname}`,
    element: <EducationBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.MOBILE_POSTPAID.pathname}`,
    element: <MobilePostpaidBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.DTH.pathname}`,
    element: <DthBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.BROADBAND.pathname}`,
    element: <BroadbandBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.LANDLINE.pathname}`,
    element: <LandlineBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.GAS.pathname}`,
    element: <GasBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.PIPEDGAS.pathname}`,
    element: <PipedGasBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.CABLE_TV.pathname}`,
    element: <CableTVBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.CREDITCARD.pathname}`,
    element: <CreditCardBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.MUNICIPAL_TAX.pathname}`,
    element: <MunicipalTaxBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.INSURANCE.pathname}`,
    element: <InsuranceBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.LOAN.pathname}`,
    element: <LoanBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.RENTAL_PAYMENT.pathname}`,
    element: <RentalPaymentBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.SUBSCRIPTION.pathname}`,
    element: <SubscriptionBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.MUNICIPAL_SERVICES.pathname}`,
    element: <MunicipalServicesBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.RECURRING_DEPOSIT.pathname}`,
    element: <RecurringDepositBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.HOUSING_SOCIETY.pathname}`,
    element: <HousingSocietyBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.HOSPITAL.pathname}`,
    element: <Hospital />,
  },

  {
    path: `${BILLERS_ROUTES.CLUBS.pathname}`,
    element: <Clubs />,
  },

  {
    path: `${BILLERS_ROUTES.CHARITY.pathname}`,
    element: <CharityBillerPage />,
  },

  {
    path: `${BILLERS_ROUTES.CREDITCARD_REGISTER.pathname}`,
    element: <CreditCardRegisterPage />,
  },

  {
    path: `${BILLERS_ROUTES.MOBILE_PREPAID_PAYMENT.pathname}`,
    element: <MobilePrepaidPaymentPage />,
  },

  {
    path: `${BILLERS_ROUTES.MOBILE_PREPAID_REGISTER.pathname}`,
    element: <MobilePrepaidRegisterPage />,
  },

  {
    path: `${BILLERS_ROUTES.NCMC.pathname}`,
    element: <NcmcPage />,
  },

  {
    path: `${BILLERS_ROUTES.NCMC_PAYMENT_PAGE.pathname}`,
    element: <NcmcPaymentPage />,
  },
  {
    path: `${BILLERS_ROUTES.PREPAID_METER.pathname}`,
    element: <PrepaidMeterPage />,
  },
  {
    path: `${BILLERS_ROUTES.NPS.pathname}`,
    element: <NpsPage />,
  },
];
