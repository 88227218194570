import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Support/Selectors/PastTickets.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { RaiseComplaintData } from "../Model/RaiseComplaint.model";

export type PastTicketsListState = {
  pastTicketsDetailData: IRemoteData<RaiseComplaintData[]>;
};

const initialState: PastTicketsListState = {
  pastTicketsDetailData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchPastTicketsList(state) {
      state.pastTicketsDetailData = state.pastTicketsDetailData.setLoading();
    },
    fetchPastTicketsListFail(state, action: PayloadAction<ApiResponseError>) {
      state.pastTicketsDetailData = state.pastTicketsDetailData.setError(
        action.payload
      );
    },
    fetchPastTicketsListSuccess(
      state,
      action: PayloadAction<RaiseComplaintData[]>
    ) {
      state.pastTicketsDetailData = state.pastTicketsDetailData.setData(
        action.payload
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const pastTicketsSlice = createSlice(sliceOptions);

export default pastTicketsSlice.reducer;
export const {
  fetchPastTicketsList,
  fetchPastTicketsListFail,
  fetchPastTicketsListSuccess,
} = pastTicketsSlice.actions;
