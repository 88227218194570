import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Payment/Selectors/Mpin.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";

export type MpinListState = {
  mpinDetailData: IRemoteData<{ isValid: boolean }>;
};

const initialState: MpinListState = {
  mpinDetailData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchMpinList(state) {
      state.mpinDetailData = state.mpinDetailData.setLoading();
    },
    fetchMpinListFail(state, action: PayloadAction<ApiResponseError>) {
      state.mpinDetailData = state.mpinDetailData.setError(action.payload);
    },
    fetchMpinListSuccess(state, action: PayloadAction<{ isValid: boolean }>) {
      state.mpinDetailData = state.mpinDetailData.setData(action.payload);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const mpinSlice = createSlice(sliceOptions);

export default mpinSlice.reducer;
export const { fetchMpinList, fetchMpinListFail, fetchMpinListSuccess } =
  mpinSlice.actions;
