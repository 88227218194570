import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "../Selectors/DownloadReceipt.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import {
  DownloadRecieptRequest,
  DownloadRecieptResponseDto,
} from "../Model/DownloadReceipt.model";

export type DownloadReceiptState = {
  downloadReceiptData: IRemoteData<DownloadRecieptResponseDto>;
  downloadRecieptRequest: DownloadRecieptRequest;
  currentBillerName: string;
  currentBillerCategory: string;
};

const initialState: DownloadReceiptState = {
  downloadReceiptData: new RemoteData(),
  downloadRecieptRequest: {} as DownloadRecieptRequest,
  currentBillerName: "",
  currentBillerCategory: "",
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchDownloadReceipt(state) {
      state.downloadReceiptData = state.downloadReceiptData.setLoading();
    },
    fetchDownloadReceiptFail(state, action: PayloadAction<ApiResponseError>) {
      state.downloadReceiptData = state.downloadReceiptData =
        state.downloadReceiptData.setError(action.payload);
    },
    fetchDownloadReceiptSuccess(
      state,
      action: PayloadAction<DownloadRecieptResponseDto>
    ) {
      state.downloadReceiptData = state.downloadReceiptData.setData(
        action.payload
      );
    },
    saveDownloadReceiptRequest(
      state,
      action: PayloadAction<DownloadRecieptRequest>
    ) {
      state.downloadRecieptRequest = action.payload;
    },
    setcurrentBillerName(state, action: PayloadAction<string>) {
      state.currentBillerName = action.payload;
    },
    setcurrentBillerCategory(state, action: PayloadAction<string>) {
      state.currentBillerCategory = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const downloadReceiptSlice = createSlice(sliceOptions);

export default downloadReceiptSlice.reducer;
export const {
  fetchDownloadReceipt,
  fetchDownloadReceiptFail,
  fetchDownloadReceiptSuccess,
  saveDownloadReceiptRequest,
  setcurrentBillerName,
  setcurrentBillerCategory,
} = downloadReceiptSlice.actions;
