import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Cashback/Selectors/MilestonesDetail.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";

import { MilestonesData } from "~/src/Redux/Cashback/Model/cashbackMilestoneDetails.model";

export type MilestonesDetailListState = {
  milestonesData: IRemoteData<MilestonesData>;
};

const initialState: MilestonesDetailListState = {
  milestonesData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchMilestonesDetailList(state) {
      state.milestonesData = state.milestonesData.setLoading();
    },
    fetchMilestonesDetailListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.milestonesData = state.milestonesData.setError(action.payload);
    },
    fetchMilestonesDetailListSuccess(
      state,
      action: PayloadAction<MilestonesData>
    ) {
      state.milestonesData = state.milestonesData.setData(action.payload);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const milestonesDetailSlice = createSlice(sliceOptions);

export default milestonesDetailSlice.reducer;
export const {
  fetchMilestonesDetailList,
  fetchMilestonesDetailListFail,
  fetchMilestonesDetailListSuccess,
} = milestonesDetailSlice.actions;
