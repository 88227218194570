import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "accountDetailsList";

const select = (state: any) => state[SLICE_NAME];
const getAccountDetailsListSelect = (state: any) => state[SLICE_NAME];

export const getAccountDetailsSelector = createSelector(
  getAccountDetailsListSelect,
  (accountDetailsList: any) => accountDetailsList
);
