import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Cashback/Selectors/CashbackActiveOffer.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { CashbackActiveOffersData } from "~/src/Redux/Cashback/Model/cashbackActiveOffer.model";

export type CashbackActiveOfferListState = {
  cashbackActiveOfferDetailData: IRemoteData<CashbackActiveOffersData>;
};

const initialState: CashbackActiveOfferListState = {
  cashbackActiveOfferDetailData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchCashbackOfferList(state) {
      state.cashbackActiveOfferDetailData =
        state.cashbackActiveOfferDetailData.setLoading();
    },
    fetchCashbackOfferListFail(state, action: PayloadAction<ApiResponseError>) {
      state.cashbackActiveOfferDetailData =
        state.cashbackActiveOfferDetailData.setError(action.payload);
    },
    fetchCashbackOfferListSuccess(
      state,
      action: PayloadAction<CashbackActiveOffersData>
    ) {
      state.cashbackActiveOfferDetailData =
        state.cashbackActiveOfferDetailData.setData(action.payload);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const cashbackActiveOfferSlice = createSlice(sliceOptions);

export default cashbackActiveOfferSlice.reducer;
export const {
  fetchCashbackOfferList,
  fetchCashbackOfferListFail,
  fetchCashbackOfferListSuccess,
} = cashbackActiveOfferSlice.actions;
