import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "scheduledBillersDetailsList";

const select = (state: any) => state[SLICE_NAME];
const getScheduledBillersDetailsListSelect = (state: any) => state[SLICE_NAME];

export const getScheduledBillersListSelector = createSelector(
  getScheduledBillersDetailsListSelect,
  (scheduledBillersDetailsList: any) => scheduledBillersDetailsList
);
