import { createSelector } from '@reduxjs/toolkit'

export const SLICE_NAME = 'prepaidOpearatorList'

const select = (state: any) => state[SLICE_NAME]
const getPrapaidOperatorListSelect = (state: any) => state[SLICE_NAME]

export const getPrepaidOperator = createSelector(
  getPrapaidOperatorListSelect,
  (prepaidOperatorList: any) => prepaidOperatorList
)
