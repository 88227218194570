import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "biller";

const select = (state: any) => state[SLICE_NAME];
const billerSelect = (state: any) => state[SLICE_NAME];

export const billersSelector = createSelector(
  billerSelect,
  (billers: any) => billers
);
