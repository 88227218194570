import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "./Selectors";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { BannerData } from "./Model";

export type BannersState = {
  banners: IRemoteData<BannerData[]>;
};

const initialState: BannersState = {
  banners: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchBanners(state) {
      state.banners = state.banners.setLoading();
    },
    fetchBannersFail(state, action: PayloadAction<ApiResponseError>) {
      state.banners = state.banners.setError(action.payload);
    },
    fetchBannersSuccess(state, action: PayloadAction<BannerData>) {
      state.banners = state.banners.setData(action.payload);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const bannersSlice = createSlice(sliceOptions);

export default bannersSlice.reducer;
export const { fetchBanners, fetchBannersSuccess, fetchBannersFail } =
  bannersSlice.actions;
