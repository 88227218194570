import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "billerAccount";

const select = (state: any) => state[SLICE_NAME];
const billerAccountSelect = (state: any) => state[SLICE_NAME];

export const billerAccountSelector = createSelector(
  billerAccountSelect,
  (biller: any) => biller
);

export const getBillerNickName = createSelector(
  billerAccountSelect,
  (biller: any) => biller
);
