export const APP_TITLE = process.env.APP_TITLE || "Axis Bank";
export const APP_DESCRIPTION =
  process.env.APP_DESCRIPTION || "Axis Bank - Billpay";
export const APP_PRECONNECT_DOMAINS = process.env.APP_PRECONNECT_DOMAINS || "";
export const AS_API_DOMAIN = process.env.API_BASE_URL || "";
export const AS_API_KEY = process.env.X_API_KEY || "";
export const AS_ENABLE_CRPTOGRAPHY =
  (process.env.DISABLE_PAYLOAD_CRYPTOGRAPHY || "").toLowerCase() === "false";
export const AS_API_TIMEOUT = parseInt(process.env.AS_API_TIMEOUT || "", 10);
export const X_API_CLIENT_ID = process.env.X_API_CLIENT_ID || "BROWSER";
