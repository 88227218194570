import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Payment/Selectors/ValidateOtp.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";

export type ValidateOtpListState = {
  validateOtpData: IRemoteData<{ isValid: boolean }>;
};

const initialState: ValidateOtpListState = {
  validateOtpData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchValidateOtpList(state) {
      state.validateOtpData = state.validateOtpData.setLoading();
    },
    fetchValidateOtpListFail(state, action: PayloadAction<ApiResponseError>) {
      state.validateOtpData = state.validateOtpData.setError(action.payload);
    },
    fetchValidateOtpListSuccess(
      state,
      action: PayloadAction<{ isValid: boolean }>
    ) {
      state.validateOtpData = state.validateOtpData.setData(action.payload);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const validateOtpListSlice = createSlice(sliceOptions);

export default validateOtpListSlice.reducer;
export const {
  fetchValidateOtpList,
  fetchValidateOtpListFail,
  fetchValidateOtpListSuccess,
} = validateOtpListSlice.actions;
