import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/T&C/Selectors/TermsConditions.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { TncData } from "~/src/Redux/T&C/model/TermsConditions.model";

export type CashbackTncListState = {
  tncDetailData: IRemoteData<TncData[]>;
};

const initialState: CashbackTncListState = {
  tncDetailData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchCashbackTncList(state) {
      state.tncDetailData = state.tncDetailData.setLoading();
    },
    fetchCashbackTncListFail(state, action: PayloadAction<ApiResponseError>) {
      state.tncDetailData = state.tncDetailData.setError(action.payload);
    },
    fetchCashbackTncListSuccess(state, action: PayloadAction<TncData[]>) {
      state.tncDetailData = state.tncDetailData.setData(action.payload);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const cashbackTncSlice = createSlice(sliceOptions);

export default cashbackTncSlice.reducer;
export const {
  fetchCashbackTncList,
  fetchCashbackTncListFail,
  fetchCashbackTncListSuccess,
} = cashbackTncSlice.actions;
