import { createAction } from "@reduxjs/toolkit";

export const getCustomerDataConfigName = "customer/data";

export const getCustomerDataConfigAction = createAction<any, string>(
  "customer/data"
);

export const setCustomerIdConfigAction = createAction<any, string>(
  "customer/setIdConfig"
);

export const setCustomerNameConfigAction = createAction<any, string>(
  "customer/setNameConfig"
);

export const setChannelIdConfigAction = createAction<any, string>(
  "customer/setChannelIdConfig"
);

export const setCustomerConfigAction = createAction<any, string>(
  "customer/setNameConfig"
);
