import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Billers/Selectors/ScheduledBillers.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { PaymentDetail } from "../../Payment/Model/PaymentDetail.model";

export type ScheduledBillersListState = {
  scheduledBillersData: IRemoteData<PaymentDetail[]>;
};

const initialState: ScheduledBillersListState = {
  scheduledBillersData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchScheduledBillersList(state) {
      state.scheduledBillersData = state.scheduledBillersData.setLoading();
    },
    fetchScheduledBillersListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.scheduledBillersData = state.scheduledBillersData.setError(
        action.payload
      );
    },
    fetchScheduledBillersListSuccess(
      state,
      action: PayloadAction<PaymentDetail[]>
    ) {
      state.scheduledBillersData = state.scheduledBillersData.setData(
        action.payload
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const scheduledBillersListSlice = createSlice(sliceOptions);

export default scheduledBillersListSlice.reducer;
export const {
  fetchScheduledBillersList,
  fetchScheduledBillersListFail,
  fetchScheduledBillersListSuccess,
} = scheduledBillersListSlice.actions;
