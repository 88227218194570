import React from "react";
import { useLottie } from "lottie-react";
import * as loaderData from "~/public/assets/images/loader/loader.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const LoaderAnimation = () => {
  const { View } = useLottie(defaultOptions, {
    height: window.screen.width < 768 ? 220 : 400,
    width: window.screen.width < 768 ? 220 : 400,
  });
  return <>{View}</>;
};

export default LoaderAnimation;
