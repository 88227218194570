import React, { useEffect, ReactElement, useState, useRef } from "react";
import { DsBox, DsBoxProps } from "@am92/react-design-system";

type BottomStickerProps = {
  children: ReactElement;
} & DsBoxProps;

export const BottomSticker = ({
  children,
  ...boxProps
}: BottomStickerProps): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const [marginY, setMarginY] = useState(0);
  useEffect(() => {
    if (ref.current) {
      setMarginY(ref.current.clientHeight);
    }
  }, [children]);
  return (
    <DsBox paddingTop={`${marginY}px`} bgcolor={"white"}>
      <DsBox
        ref={ref}
        position="fixed"
        maxWidth="600px"
        bottom={0}
        width="100%"
        zIndex={"banner"}
        {...boxProps}
        bgcolor="white"
      >
        {children}
      </DsBox>
    </DsBox>
  );
};
