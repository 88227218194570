import { createSelector } from '@reduxjs/toolkit'

export const SLICE_NAME = 'prepaidCircleList'

const select = (state: any) => state[SLICE_NAME]
const getCircleListSelect = (state: any) => state[SLICE_NAME]

export const getPrepaidCircle = createSelector(
  getCircleListSelect,
  (prepaidCircleList: any) => prepaidCircleList
)
