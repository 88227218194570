import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "validateBill";

const select = (state: any) => state[SLICE_NAME];
const validateBillSelect = (state: any) => state[SLICE_NAME];

export const validateBillSelector = createSelector(
  validateBillSelect,
  (validateBill: any) => validateBill
);
